import { useState,useEffect } from "react"
import hylkyservices from '../services/hylkykortti'
//import { useNavigate } from "react-router-dom"


import { Container } from "react-bootstrap"
import '../App.css'


const Etusivu =({clearAllFilters}) =>{

//const [categories,setCategories] = useState([])
//const [articleTypes,setArticleTypes] = useState([])
//const [materials,setMaterials]=useState([])
const [acuteBox,setAcuteBox] = useState(null)
const frontPageNumber =2

/*const iconStyle={
  width:'35px',
  height:'35px'
}
const linkStyle={
  textdecoration: 'none'
}*/


useEffect(()=>{
  const fetchPage= async () =>{
   const newAccuteBox= await hylkyservices.getWPPage(frontPageNumber)
    setAcuteBox(newAccuteBox.content.rendered)
  }
  if(!acuteBox){
    fetchPage()
  }
    clearAllFilters()
  //setWreckTypeFilters([0])
  //setWreckClassFilters([0])
  //setSearchString('')

} )

/*useEffect(()=>{
  
  const fetchCategories= async ()=>{
    const newWreckTypes= await hylkyservices.getKohdeTyyppiMain()
    setCategories(
      newWreckTypes.map(type =>{
        return {
          id: type.id,
          name: type.name,
          icon: `${type.meta.icon}`
        }
      })
    )
  }
  if(categories.length ===0){
    fetchCategories()
  }

  const fetchArticleTypes= async () =>{
    //const wreckId =id>0?id:519
  const newArticleTypes= await hylkyservices.getKohdeluokitus()
    setArticleTypes(
      newArticleTypes.map(type =>{
        return {
          id: type.id,
          name: type.name,
          icon: `${type.meta.icon}`
        }
      })
    )

  const newMaterials= await hylkyservices.getMateriaaliMain()
  setMaterials(
    newMaterials.map(type =>{
      return {
        id: type.id,
        name: type.name
      }
    })
  )
  
  
}
if(articleTypes.length ===0){
  fetchArticleTypes()
}
console.log('Effect run')
},[])*/

//const navigate=useNavigate()

return (
  <Container>
    
    
    <Container>
      <p>
      <center>
      <img class="img-fluid" src="https://www.hylyt.net/wp-content/uploads/2023/01/loitokari_etusivubanneri_final.gif" alt="web_banner_728x90"  />
      </center>
      </p>
      <div dangerouslySetInnerHTML={{ __html: acuteBox }} />
    </Container>
  
    {/*<Container>
      <Stack gap={5} >
        <h4>Kohdetyyppi ja paikannustarkkuus</h4>
        <Stack direction="horizontal" gap={5} >
          { categories.map(
            category=>(
              <h3 
                key={category.id} 
                className="frontPageLink ms-auto"
                value={category.id}
                onClick={(e)=>{
                  setWreckTypeFilters([Number(e.target.attributes.value.nodeValue)]) 
                  //console.log('ee oli',e)
                  navigate('hylkylista')
                }}


              > 
                <span to={`/cat/${category.id}`} style={linkStyle} value={category.id}><img value={category.id} src={category.icon} style={iconStyle} /> {category.name} </span>
              </h3>) 
          )}
        </Stack>
        <h4>Hylky- ja artikkeliluokitus</h4>
        <Stack direction="horizontal" gap={5} >
          { articleTypes.map(
            category=>(
              <h3 
                key={category.id} 
                className="frontPageLink ms-auto" 
                onClick={(e)=>{
                  setWreckClassFilters([Number(e.target.attributes.value.nodeValue)]) 
                  navigate('hylkylista')
                  console.log('ee oli',e)
                  
                }}
              > 
                <span to={`/loc/${category.id}`} value={category.id} style={linkStyle}><img value={category.id} src={category.icon} style={iconStyle} /> {category.name} </span>
              </h3>) 
          )}
        </Stack>
        <h4>Materiaalit</h4>
        <Stack direction="horizontal" gap={5} >
        { materials.map(
            category=>(
              <h3 key={category.id} className="frontPageLink ms-auto" > 
                <Link to={`/materiaali/${category.id}`} style={linkStyle} style={iconStyle}>{category.name} </Link>
              </h3>) 
            )}
          </Stack>
      </Stack>  
    </Container>*/}
  </Container>
)

} 
export default Etusivu