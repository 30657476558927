import { useState } from "react"
//import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
//import sendFeedBack from '../services/feedback'
import axios from "axios"
import config from "../config"
import { Alert } from "react-bootstrap"


const ReportNewWreckForm =() =>{
  const [formStatus, setFormStatus] = useState('Lähetä')
  const [wreckName,setWreckName] = useState("")
  const [kohdeTyyppi,setKohdeTyyppi] = useState('Laiva')
  const [alusTyyppi,setAlusTyyppi] = useState("")
  const [kotipaikka,setKotipaikka] =useState("")
  const [rakennusVuosi,setRakennusVuosi] =useState("")
  const [rakennusMateriaali,setRakennusMateriaali] =useState("")
  const [pituus,setPituus] = useState("")
  const [leveys,setLeveys] =useState("")
  const [vetoisuus,setVetoisuus] =useState("")
  const [museo,setMuseo]=useState(false)
  const [sota,setSota]=useState(false)

  const [uppoamisAika,setUppoamisAika] =useState("")
  const [uppoamisPaikka,setUppoamisPaikka] =useState("")
  const [latitudeP,setLatitudeP] =useState("")
  const [longitudeI,setLongitudeI] =useState("")

  const [minimiSyvyys,setMinimiSyvyys]=useState("")
  const [pohjanSyvyys,setPohjanSyvyys]=useState("")
  const [pohjanLaatu,setPohjanLaatu] = useState("")
  const [nakyvyys,setNakyvyys]=useState("")
  const [asento,setAsento]=useState("")
  const [kohteenKunto,setKohteenKunto]=useState("")
  const [huomioitavaa,setHuomioitavaa]=useState("")
  const [tarina,setTarina]= useState("")
  const [lahteet,setLahteet]=useState("")


  const [name,setName]= useState("")
  const [email,setEmail]=useState("")
  const [phone,setPhone]=useState("")
  const [address,setAddress]=useState("")

  const handleNameChange=(e)=>{
    setName(e.target.value)  
  }
  const handleEmailChange=(e) =>{
    setEmail(e.target.value)
  }

  const handleMuseoChange=(e) =>{
    setMuseo(!museo)
  }
  const handleSotaChange =(e) =>{
    setSota(!sota)
  }

  const styleForm =  { 
    background: '#fafafa',
    border: '1px solid #cfcfcf',
  }

  const handleFormSend = async (event) =>{
    event.preventDefault()
    console.log('button clicked', event.target)
    const feedback = {
      HylynNimi: wreckName,
      kohdeTyyppi: kohdeTyyppi,
      alusTyyppi: alusTyyppi,
      kotipaikka: kotipaikka,
      rakennusVuosi,
      rakennusMateriaali,
      pituus,
      leveys,
      vetoisuus,
      museo,
      sota,
      uppoamisAika,
      uppoamisPaikka,
      latitudeP,
      longitudeI,
      minimiSyvyys,
      pohjanSyvyys,
      pohjanLaatu, 
      nakyvyys,
      asento,
      kohteenKunto,
      huomioitavaa,
      tarina,
      lahteet,
      name: name,
      email: email,
      phone,
      address
      
    }
    axios.post(`${config.FORM_SERVER}/api/wreckdiscovery`,feedback)
    .then(responce =>{
      console.log('server responce',responce.data)
      if (responce.data.status && responce.data.status === 'ok'){
        succesFullSend()
      }else{
        failedSend()
      }
      
    })
    .catch(error =>{
      console.log('error oli', error)
      failedSend()
    })
  }

  const succesFullSend=() =>{
    setWreckName("")
    setKohdeTyyppi("")
    setAlusTyyppi('Laiva')
    setKotipaikka("")
    setRakennusVuosi("")
    setRakennusMateriaali("")
    setPituus("")
    setLeveys("")
    setVetoisuus("")
    setMuseo(false)
    setSota(false)
    setUppoamisAika("")
    setUppoamisPaikka("")
    setLatitudeP("")
    setLongitudeI("")
    setMinimiSyvyys("")
    setPohjanSyvyys("")
    setNakyvyys("")
    setAsento("")
    setKohteenKunto("")
    setHuomioitavaa("")
    setTarina("")
    setLahteet("")
    setName("")
    setEmail("")
    setPhone("")
    setAddress("")
    setFormStatus('Lähetä Uusi')

  }

  const failedSend = ()=>{
    setFormStatus('Yritä uudelleen')
  }


  const statusMsg=(status)=>{
    switch(status) {
      case 'Lähetä Uusi':
        return( 
          <Alert variant="success">
            <Alert.Heading>Uusi hylkytieto on vastaanotettu</Alert.Heading>
            <p>Lomake on vastaanotettu, kiitos antamistasi tiedoista</p>
          </Alert>
        );
      case 'Yritä uudelleen':
        return( 
          <Alert variant="warning">
            <Alert.Heading>Jotain meni pieleen</Alert.Heading>
            <p>Lomakkeen lähetys epäonnistui, yritä uudelleen</p>
          </Alert>
        ); 
        default:
        return <></>
    }
  }




  return (
    <div style={styleForm}>
    <form onSubmit={handleFormSend}  >
      <div>
        <h4>Kerro uudesta hylkyhavainnosta</h4>
        <div>
          <fieldset>
          <legend>Perustiedot</legend>
          <label className="container mt-2">Hylyn nimi <br/>
            <input 
              className="form-control"
              type="text" 
              id="wreckName" 
              name="wreckName" 
              value={wreckName} 
              onChange={(e)=>setWreckName(e.target.value)} 
            />
          </label> 
          <label className="container mt-2">Kohdetyyppi <br/>
            <select
              className="form-control"
              type="text" 
              id="kohdeTyyppi" 
              name="kohdeTyyppi" 
              value={kohdeTyyppi} 
              onChange={(e)=>setKohdeTyyppi(e.target.value)} 
            >
              <option value='Laiva' selected>Laiva</option>
              <option value ='Lentokone'>Lentokone</option>
              <option value = 'Sukellusvene'>Sukellusvene</option>
              <option value = 'Tykki'>Tykki</option>
              <option value ='Muu'>Muu</option>
            </select>
        </label>
        <label className="container mt-2">Alustyyppi <br/>
            <input 
              className="form-control"
              type="text" 
              id="alusTyyppi" 
              name="alusTyyppi" 
              value={alusTyyppi} 
              onChange={(e)=>setAlusTyyppi(e.target.value)} 
              placeholder='esim. parkki, jolla...'
            />
        </label><br/> 
        <label className="container mt-2">Kotipaikka <br/>
            <input 
              className="form-control"
              type="text" 
              id="kotipaikka" 
              name="kotipaikka" 
              value={kotipaikka} 
              onChange={(e)=>setKotipaikka(e.target.value)} 
            />
        </label><br/>
        <label className="container mt-2">Rakennusvuosi <br/>
            <input 
              className="form-control"
              type="text" 
              id="rakennusVuosi" 
              name="rakennusVuosi" 
              value={rakennusVuosi} 
              onChange={(e)=>setRakennusVuosi(e.target.value)} 
            />
        </label><br/> 
        <label className="container mt-2">Rakennusmateriaali <br/>
            <input 
              className="form-control"
              type="text" 
              id="rakennusMateriaali" 
              name="rakennusMateriaali" 
              value={rakennusMateriaali} 
              onChange={(e)=>setRakennusMateriaali(e.target.value)} 
            />
        </label><br/> 
        <label className="container mt-2">Pituus (m)<br/>
            <input
              className="form-control" 
              type="text" 
              id="pituus" 
              name="pituus" 
              value={pituus} 
              onChange={(e)=>setPituus(e.target.value)} 
            />
        </label>
        <label className="container mt-2">Leveys (m)<br/>
            <input 
              className="form-control"
              type="text" 
              id="leveys" 
              name="leveys" 
              value={leveys} 
              onChange={(e)=>setLeveys(e.target.value)} 
            />
        </label><br/>  
        <label className="container mt-2">Vetoisuus <br/>
            <input 
              className="form-control"
              type="text" 
              id="vetoisuus" 
              name="vetoisuus" 
              value={vetoisuus} 
              onChange={(e)=>setVetoisuus(e.target.value)} 
            />
        </label><br/> 
        <fieldset >
          <legend>Hylkytyyppi</legend>
          <label className="container mt-2"> 
              <input 
                type="checkbox" 
                id="Museo" 
                name="Museo" 
                checked = {museo}
                onChange={handleMuseoChange}
              /> Museohylky
          </label>
          <label className="container mt-2"> 
              <input 
                type="checkbox" 
                id="Sota" 
                name="Sota" 
                checked ={sota}
                onChange={handleSotaChange}
              /> Sotahylky
          </label><br/> 
        </fieldset> 
        </fieldset>
        <fieldset>
          <legend>Uppoaminen</legend>
          <label className="container mt-2">Aika <br/>
              <input 
                className="form-control"
                type="text" 
                id="uppoamisAika" 
                name="uppoamisAika" 
                value={uppoamisAika} 
                onChange={(e)=>setUppoamisAika(e.target.value)} 
              />
          </label><br/> 
          <label className="container mt-2">Paikka <br/>
              <input 
                className="form-control"
                type="text" 
                id="uppoamisPaikka" 
                name="uppoamisPaikka" 
                value={uppoamisPaikka} 
                onChange={(e)=>setUppoamisPaikka(e.target.value)} 
              />
          </label><br/> 
          <fieldset>
            <legend>Koordinaatit (WGS84)</legend>  
            <label className="container mt-2">latitude P <br/>
                <input 
                  className="form-control"
                  type="text" 
                  id="latitudeP" 
                  name="latitudeP" 
                  value={latitudeP} 
                  onChange={(e)=>setLatitudeP(e.target.value)} 
                  placeholder='aste.desimaalit'
                />   
            </label> ,  
             <label className="container mt-2">longitude I <br/>
              <input 
                className="form-control"
                type="text" 
                id="longitudeI" 
                name="longitudeI" 
                value={longitudeI} 
                onChange={(e)=>setLongitudeI(e.target.value)} 
                placeholder='aste.desimaalit'
              />
            </label><br/>
          </fieldset>
        </fieldset>
        <fieldset>
          <legend>Sukelluskohde</legend>
          <label className="container mt-2">Minimisyvyys (m)<br/>
            <input 
              className="form-control"
              type="text" 
              id="minimiSyvyys" 
              name="minimiSyvyys" 
              value={minimiSyvyys} 
              onChange={(e)=>setMinimiSyvyys(e.target.value)} 
            />
          </label><br/> 
          <label className="container mt-2">Pohjan syvyys (m)<br/>
            <input 
              className="form-control"
              type="text" 
              id="pohjanSyvyys" 
              name="pohjanSyvyys" 
              value={pohjanSyvyys} 
              onChange={(e)=>setPohjanSyvyys(e.target.value)} 
            />
          </label><br/>  
          <label className="container mt-2">Pohjan laatu<br/>
            <input 
              className="form-control"
              type="text" 
              id="pohjanLaatu" 
              name="pohjanLaatu" 
              value={pohjanLaatu} 
              onChange={(e)=>setPohjanLaatu(e.target.value)} 
              placeholder = 'esim. hiekka, muta...'
            />
          </label><br/>  
          <label className="container mt-2">Näkyvyys <br/>
            <input 
              className="form-control"
              type="text" 
              id="nakyvyys" 
              name="nakyvyys" 
              value={nakyvyys} 
              onChange={(e)=>setNakyvyys(e.target.value)} 
            />
          </label><br/>  
          <label className="container mt-2">Asento <br/>
            <input 
              className="form-control"
              type="text" 
              id="asento" 
              name="asento" 
              value={asento} 
              onChange={(e)=>setAsento(e.target.value)} 
            />
          </label><br/>
          <label className="container mt-2">Kohteen kunto<br/>
            <input
              className="form-control" 
              type="text" 
              id="kohteenKunto" 
              name="kohteenKunto" 
              value={kohteenKunto} 
              onChange={(e)=>setKohteenKunto(e.target.value)} 
            />
          </label><br/>
          <label className="container mt-2">Huomioitavaa hylylle sukellettaessa <br/>
            <textarea 
              className="form-control"
              rows={4} 
              id="huomioitavaa" 
              name="huomioitavaa" 
              value={huomioitavaa} 
              onChange={(e)=>setHuomioitavaa(e.target.value)} 
            />
          </label><br/>
        </fieldset>
        <fieldset>
          <label className="container mt-2">Tarina <br/>
            <textarea 
              className="form-control"
              rows={6}
              id="tarina" 
              name="tarina" 
              value={tarina} 
              onChange={(e)=>setTarina(e.target.value)} 
            />
          </label><br/>
          <label className="container mt-2">Lähteet <br/>
            <textarea 
              className="form-control"
              rows={4}
              id="lahteet" 
              name="lahteet" 
              value={lahteet} 
              onChange={(e)=>setLahteet(e.target.value)} 
            />
          </label><br/>
        </fieldset>
        </div>
        <fieldset>
          <legend>Lähettäjän tiedot</legend>
          <label className="container mt-2">Nimi (Pakollinen)<br/>
            <input className="form-control" type="text" id="senderName" name="senderName" value={name} onChange={handleNameChange} required/>
          </label><br/>
          <label className="container mt-2">Sähköposti (pakollinen) * <br/>
            <input className="form-control" type="email" id="email" name="email" value={email} onChange={handleEmailChange} required />
          </label><br/>

          <label className="container mt-2">Puhelin<br/>
            <input type="text" className="form-control" id="phone" name="phone" value={phone} onChange={(e)=>setPhone(e.target.value)}  />
          </label><br/>
          <label className="container mt-2">Osoite<br/>
            <input type="text" className="form-control" id="address" name="address" value={address} onChange={(e)=>setAddress(e.target.value)}  />
          </label><br/><br/>
        </fieldset>

        <button className="btn btn-danger" type="submit">
          {formStatus}
        </button>
      </div>
    </form>
    {statusMsg(formStatus)}   
    </div>
  )

}

export default ReportNewWreckForm