//import { useState, useEffect } from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner'
//import hylkyservices from '../services/hylkykortti'
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import React from 'react';



const Media = ({page, media, showThumbnails,showPlayButton,thumbnailPosition,showBullets}) =>{
  
  const style = { 
    //border: '5px solid pink' 
    backgroundColor: '#e9e9e9',
    border: '1px solid #cfcfcf'
  }
  const gt ={
    color: 'white'
  }

  const handleImageError =(event) => {
    if (this.props.defaultImage &&
        event.target.src.indexOf(this.props.defaultImage) === -1) {
      event.target.src = this.props.defaultImage;
    }
  }
   

  const myImageGalleryRenderer=(item)=> {
    // your own image error handling
    const onImageError = handleImageError
    //console.log('imagegallery item: ', item)
    //src={item.props.itemSrc}
    return (
      <React.Fragment>
        <img
          className="image-gallery-image"
         
          
          src={item.original}
          alt={item.originalAlt}
          srcSet={item.srcSet}
          height={item.originalHeight}
          width={item.originalWidth}
          sizes={item.sizes}
          title={item.originalTitle}
          
          
        />
        {
          
          item.description && item.title? (
            <div className='image-gallery-description'  style={{color:'white', textAlign: 'left'}}>
              <h5>{item.title}</h5> 
              <div style={{color:'white', textAlign: 'left'}} dangerouslySetInnerHTML={{ __html: item.description }} />
            </div>
          ):(
            item.description && (
              <div className='image-gallery-description'>
              <div  style={{color:'white', textAlign: 'left'}} dangerouslySetInnerHTML={{ __html: item.description }} />
            </div>

            )
          )
        }
      </React.Fragment>
    )
  }

  

  if(media.length===0){
    return(
      <div>
      Loading...
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      </div>
    )
  }




  return(
    <Container>
      <Row>
        <Col md={{ span: 9, offset: 1 }} style={style}>
          <ImageGallery 
            items={media} 
            showBullets={showBullets} 
            showThumbnails={showThumbnails} 
            showPlayButton={showPlayButton}
            thumbnailPosition ={thumbnailPosition}
            renderItem={(item)=>myImageGalleryRenderer(item)}
          />
        </Col>
      </Row>
    </Container>
  )



} 
Media.defaultProps = {
  showBullets: true,
  showThumbnails: true,
  showPlayButton: true,
  thumbnailPosition: 'bottom'

};
export default Media