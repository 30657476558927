//import { propTypes } from "react-bootstrap/esm/Image"

const Sketchfab =({caption,id,title,url})=>{
  //const with = 640

  // Set default attributes
	/*$atts = shortcode_atts(
		array(
			'id'          => '',
			'width'       => 640,
			'height'      => 480,
			'autostart'   => '0',
			'autospin'    => '0'
		), $atts*/

    
  //const autostart=0
  //const autospin=0
  const width=1000
  const height=500
  const src = `${url}/embed?autostart=0&autospin=0`
  const extra ='preload=1&amp;ui_controls=0&amp;ui_infos=0&amp;ui_inspector=0&amp;ui_stop=0&amp;ui_watermark=0&amp;ui_watermark_link=0" frameborder="0" allow="autoplay; fullscreen; vr" mozallowfullscreen="true" webkitallowfullscreen="true"'
  //src="https://sketchfab.com/models/' . $atts['id'] . '/embed?autostart=' . $atts['autostart'] . '&autospin=' . $atts['autospin'] . '" frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>';

    // Embed code
  return(
    <div>
      <h3>{title}</h3>
      <iframe 
        title='3d-malli' 
        width={width} 
        height={height} 
        src={src} 
        frameBorder={0} 
         
        allowFullScreen={true}
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        sandbox="allow-scripts allow-same-origin allow-popups allow-forms" 
        allow="autoplay; fullscreen; xr-spatial-tracking" 
        xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share allowfullscreen 
      ></iframe> 
      <div dangerouslySetInnerHTML={{ __html: caption}} />
    </div>
  )


}

const Sketchfabs =({models})=>{

  return(
    <div>
      <h3>3d-mallit</h3>
      {models.map(model => <Sketchfab caption={model.caption} id={model.id} title={model.title} url={model.url} /> )}
    </div>
  )

} 

export default Sketchfabs