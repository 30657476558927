//import axios from "axios"
//import { useState,useEffect } from "react"
import { Row,Col, Container } from "react-bootstrap"
//import { useNavigate } from "react-router-dom"
//import  SearchAutocomplete  from './SearchAutocomplete'
import SearchComboBox from "./SearchComboBox"
import '../SearchForm.css'
//import DatalistInput from "react-datalist-input"


const SearchForm =(
    {
      filteredWrecks,
      searchString,
      setSearchString,
      categoryCatalogMain,
    }
  )=>{

if(!categoryCatalogMain ||categoryCatalogMain.length===0){
  return (<div>loading...</div>)
}

  return(
<Container  className="searchForm" fluid>
    

<Row className="gh-6 mt-3">
  <Col md className="align-middle gt-3 gx-3">
    <SearchComboBox 
      filteredWrecks={filteredWrecks} 
      searchString={searchString} 
      setSearchString={setSearchString}
     
    />
  </Col>
</Row>

   
    </Container>
  )


}
export default SearchForm