import axios from 'axios'
import config from '../config'
//import { useAxiosFetch } from '../hooks/useAxiosFetc' 

const server = config.SERVER
const baseUrl= '/wp-json/wp/v2/ait-dir-item'
const baseUrlLayout ='/wp-content/themes/vesanteema/hylyt_schema.json'
const baseUrlComments = '/wp-json/wp/v2/comments?post='
const baseUrlMedia = '/wp-json/wp/v2/media?parent='
const baseUrlHylkylista = '/wp-json/wp/v2/ait-dir-item'
//X-WP-Total: 2473
//X-WP-TotalPages: 248

console.log(`Server running on  ${server}`)

const getPage =  (id) => {
  
  const request = axios.get(`${server}${baseUrl}/${id}`)
  return request.then(response => response.data)
}
const getPageLayout =(page) =>{
  const request = axios.get(`${server}${baseUrlLayout}`)
  return request.then(response => response.data.wreckcard_display) 
}
const getComments = (id)=>{
  const request = axios.get(`${server}${baseUrlComments}${id}`) 
  return request.then(response => response.data)
}
const getMedia = (id)=>{
  const request = axios.get(`${server}${baseUrlMedia}${id}`) 
  return request.then(response => response.data)
}
const getWreckList = (cat,id,pageNum,perPage,orderby,order)=>{
  const filter = '&_fields=id,title,date,modified,excerpt,marker,cat_icon,loc_icons,meta.hylytnet_muut_nimet,featured_media,_links,_embedded&_embed=wp:featuredmedia'
  const perPageParam=perPage?`&per_page=${perPage}`:''
  console.log('mun parametrit', cat,id,pageNum,perPage,orderby,order )
  order=order==='nouseva'?'asc':'desc'
  const orderbyParam = `&orderby=${orderby}&order=${order}`
  const request = axios.get(`${server}${baseUrlHylkylista}?${cat}=${id}${perPageParam}${filter}&page=${pageNum}${orderbyParam}`) 
  return request.then(response => response)

}
const getWreckListAdv =(categories,locations,pageNum,perPage,orderby,order) =>{
  const base =  '/wp-json/wp/v2/ait-dir-item?status=publish'
  /*const params = {
    method: "GET",
    url: base,
    params: {
      _fields: 'id,title,date,modified,excerpt,marker,cat_icon,loc_icons,meta.hylytnet_muut_nimet,featured_media,_links,_embedded',
      _embed: 'wp:featuredmedia',
      per_page: '22',
      include: fetchIds.join(',')
    },
  }*/


  const filter = '&_fields=id,title,date,modified,excerpt,marker,cat_icon,loc_icons,meta.hylytnet_muut_nimet,featured_media,_links,_embedded&_embed=wp:featuredmedia'
  const cats =categories.length>0?`&ait-dir-item-category=${categories}`:''
  const locs = locations.length>0?`&ait-dir-item-location=${locations}`:''
  //const sort=sorting.length>0?sorting:''
  order=order==='nouseva'?'asc':'desc'
  //const orderbyParam = `&orderby=${orderby}&order=${order}`
  const perPageParam=perPage?`&per_page=${perPage}`:''
  const request = axios.get(`${server}${base}${cats}${locs}${perPageParam}${filter}&page=${pageNum}`) 
  console.log('getWreckListAdv',request)
  return request.then(response => response) 
}

const foo =async (axiosParams)=>{
  const response = await axios.request(axiosParams);
  return response.data

}

const getKohdeluokitus =() =>{
  const base = '/wp-json/wp/v2/kohdeluokitus'
  const request = axios.get(`${server}${base}`) 
  return request.then(response => response.data)

}

const getMateriaaliMain =() =>{
  const base = '/wp-json/wp/v2/materiaali?parent=0'
  const request = axios.get(`${server}${base}`) 
  return request.then(response => response.data)

}

const getKohdeTyyppiMain =() =>{
  const base = '/wp-json/wp/v2/kohdetyyppi?parent=0'
  const request = axios.get(`${server}${base}`) 
  return request.then(response => response.data)
}
const getKohdeTyyppiTax =() =>{
  const base = '/wp-json/wp/v2/kohdetyyppi?per_page=100'
  const request = axios.get(`${server}${base}`) 
  return request.then(response => response.data)
}


const getWPPage=(num) =>{
  const base = `${server}/wp-json/wp/v2/pages/${num}`
  const request = axios.get(base) 
  return request.then(response => response.data)

}

export default {
  getPage, 
  getPageLayout, 
  getComments,
  getMedia,
  getWreckList,
  getWreckListAdv,
  getKohdeluokitus,
  getMateriaaliMain, 
  getWPPage, 
  getKohdeTyyppiMain,
  foo,
  getKohdeTyyppiTax
}