import { useEffect, useState } from "react"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const Sanasto =({content})=>{
  const [terms,setTerms] =useState([])
  const [key, setKey] = useState('A');

  const splitContent= (what)=>{
    return what.replace('[tabs]','').replace('[/tabs]','').split('[/tab]')
  }

  useEffect(()=>{

    if(content.length >0){
      const foo = content
        .replace('[tabs]','')
        .replace('[/tabs]','')
        .split('[/tab]')
        .map(element =>(
          {
            tabKey: element.charAt(19),
            tabVal: element.replace(/\[tab title=&#8221;(.)&#8221;\]/g,'')
          }
        ))
      /*foo.array.forEach(element => {
        
      });*/
      setTerms(foo)
    }

  },[content])

  if(terms.length ===0){
    return (
      <div>Loading...</div>
    )
  }

  return(
    <div>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        {terms.map(split => (
          <Tab 
            eventKey={split.tabKey} 
            title={split.tabKey}>
              <p dangerouslySetInnerHTML={{ __html: split.tabVal }} />
          </Tab>)
        )}
      </Tabs>
    </div>
  )
}
export default Sanasto