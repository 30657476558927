import { useState,useEffect } from "react"
import { Stack,Container} from "react-bootstrap"
import { useNavigate } from "react-router-dom"

const WreckTypeFilter =({wreckTypeFilters,setWreckTypeFilters,categoryCatalogMain,label,setPath,pathOrder,handleContentUpdate})=>{

  /*const notSelectedType= {
    border: '1px',
    borderColor: 'gray',
    borderStyle: 'double',
    borderRadius: '15px',
    padding: '5px',
    backgroundColor: '#ffffff',
  }  

const selectedStyle={
  border: '1px',
  borderColor: 'black',
  borderStyle: 'double',
  borderRadius: '15px',
  padding: '5px',
  backgroundColor: '#eaecef'
}*/
const notSelectedType= {
  border: '1px solid #70685a',
  borderRadius: '15px',
  padding: window.innerWidth > 806?'5px':'5px',
  minWidth: '40px',
  minHeight: '40px',
  paddingLeft: '20px',
  backgroundColor: '#8c8271',
  boxShadow: '0 1px 0 #9e9687 inset, 0 1px 0 #565046',
  marginBottom: '1rem',
  backgroundSize: window.innerWidth > 806?'15px 15px':'25px 25px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '5px center'
  
}

const selectedStyle={
  border: '1px',
  borderColor: 'black',
  borderStyle: 'double',
  borderRadius: '15px',
  minWidth: '40px',
  minHeight: '40px',
  padding: window.innerWidth > 806?'5px':'5px',
  paddingLeft: window.innerWidth > 806?'20px':'30px',
  backgroundColor: '#eaecef',
  marginBottom: '1rem',
  backgroundSize: window.innerWidth > 806?'15px 15px':'25px 25px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '5px center'
}

/*const iconStyle={
  width: '15px',
  height: '15px'

}*/

/*const iconStylesm={
  backgroundSize: '25px 25px'
}*/
  const navigate = useNavigate()

 useEffect( ()=>{
    const newChecked = categoryCatalogMain.map(category=>(
      {
        name: category.name,
        value: false,
        controller_for: category.slug,
        icon: category.icon,
        sname: category.sname?category.sname:null

      }
    ))
    setChecked(newChecked)
  },[categoryCatalogMain])

  

  const [checked,setChecked]= useState([])

  useEffect(()=>{
    if(checked.length>0){
      const str=checked.filter(check=>check.value).map(c=>c.name)
      const foo= str?{name: label, selection: str,pathOrder}: {name: label, selection: [],pathOrder}
      console.log('olio on:',foo)
      setPath(foo)
    }
  },[checked])

  useEffect(()=>{
    if(wreckTypeFilters && wreckTypeFilters.length ){
      if(wreckTypeFilters[0]===0){
        //setChecked([])
        const newObj= checked.map(item=>({ ...item, value: false}))
        setChecked(newObj)
      }
    }
    
  },[wreckTypeFilters])



/*const printPath=()=>{
  const str=checked.filter(check=>check.value).map(c=>c.name)
  return str?{name: label, selection: str}: {name: label, selection: []}
}*/
  

  

  const handleFilterSelection =(e)=>{
    const newObj= checked.map(item=>(item.controller_for!==e.target.id
        ?item
        :{ ...item, value: !item.value})
    )
    console.log('foo',checked,e,'newobj:',newObj)
    setChecked(newObj)
    const ids=makeFilterArray(categoryCatalogMain,newObj)
    setWreckTypeFilters(ids)
    //const str = printPath()
    //setPath(str)
    navigate('hylkylista')
    //handleContentUpdate()
  }
  

  const makeFilterArray =(allCategoriesList, controllers)=>{
    const chosenFilters =controllers.filter(controller =>controller.value).map(c=>c.controller_for) //['-gps','-noin']
    const idsToShow= allCategoriesList.filter(catFromAll=>
      chosenFilters.some(cat =>catFromAll.slug.replace('erittain-karkea','erittainkarkea').endsWith(cat)))
      .map(a=>(a.id))
    return idsToShow
  }

  if(categoryCatalogMain.length===0){
    return (<p>Loading...</p>)
  }


  return(
    
    <Container className="positionPrecisionFilter" fluid>
      <label>{label}</label>
      <Stack direction="horizontal" gap={window.innerWidth > 806?3:2}>
        {checked.map((check,idx)=>(
          <button
          key={check.controller_for}
          value={check.value}
          id={check.controller_for}
          style={check.value
            ?{...selectedStyle,backgroundImage: `url(${check.icon})` }
            :{...notSelectedType,backgroundImage: `url(${check.icon})` }
          }
          onClick={(e)=>handleFilterSelection(e)}
          > {window.innerWidth > 806?check.name:" "}{ check.value?' ×':null}</button>
        ))}
       
      </Stack>
      


      </Container>
      
  )
  
} 
export default WreckTypeFilter