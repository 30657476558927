import '../App.css';
import { useState,useEffect } from "react"
import { Link} from "react-router-dom"
import {Container,Row,Col,Stack} from 'react-bootstrap'
import SortForm from './SortForm';
import hylkyservices from '../services/hylkykortti'
//import { useAxiosFetch } from '../hooks/useAxiosFetch';
import config from '../config'


const HylkylistaItem = ({wreck}) =>{
  const[featured,setFeatured]=useState(null)
  /*const [title,setTitle] = useState(null)
  const [created,setCreated]= useState(null)
  const [modified,setModified] = useState(null)
  const [icons,setIcons] =useState([])
  const [excerpt,setExcerpt] = useState(null)
  */

  useEffect(()=>{
    setFeatured('/img/default-post-image.jpg')
   if(wreck._embedded && wreck._embedded['wp:featuredmedia']){
    const newImg =wreck._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url 
    setFeatured(newImg)
   } 

  },[wreck])

  const cdate = (new Date(wreck.date)).toLocaleDateString('fi-FI')
  const cmodified = (new Date(wreck.modified)).toLocaleDateString('fi-FI')
  const iconStyle ={
    width: '25px',
    height: '25px'
  }
  const imgStyle ={
    width: '112px',
    height: '112px',
    border: '1px solid #b6b6b6',
    padding: '5px',
    margin: '5'
  }
  const txtStyle={
    paddingLeft: '15px',
    marginLeft: '15px'
  }
  //console.log('wrecklist-item',wreck)
  //kokeellinen Card
  /*
  return(
    
    <Card style={{ width: '18rem' }}> 
      {featured?<Card.Img variant="top" src={featured} />:<></>}
     <Card.Body>
      <Card.Title><Link style={{color: "#5b7b95"}} to={`/hylkykortti/${wreck.id}`}>{wreck.title.rendered}
          {wreck.meta.hylytnet_muut_nimet.length===0?<></>
          :<>({wreck.meta.hylytnet_muut_nimet.join(',')})</>}
          <span>
            <img src={`http://localhost:8080/${wreck.marker}`} style={iconStyle} /> 
          {wreck.loc_icons.map(icon =>(
            <img src={`http://localhost:8080/${icon}`} style={iconStyle} />
          ))}
          </span>
          </Link></Card.Title>
      <Card.Text>
      <div>Julkaistu: {cdate}, Muokattu: {cmodified}</div>
      <div dangerouslySetInnerHTML={{ __html: wreck.excerpt.rendered }} />
      </Card.Text>
     </Card.Body>
      
   
    </Card>
  )
*/
  const getNameString = (wreck)=>{
    if(wreck.meta){
    if(  wreck.meta.hylytnet_muut_nimet.length>0){
      //
      return wreck.title.rendered.concat(' (').concat(wreck.meta.hylytnet_muut_nimet.join(',')).concat(')')
    }
  }
    return wreck.title.rendered
    
  }

  return(
    
    <Row key={wreck.id}>
      <Col sm="1" lg="1">
      <img src={featured} style={imgStyle} alt={'Featured img'}/>
      </Col>
      <Col sm="11" lg="11">
      <div style={txtStyle}>
      <h3><Link style={{color: "#5b7b95"}} to={`/hylkykortti/${wreck.id}`}>{getNameString(wreck)}
          <span>
            <img src={`${wreck.cat_icon}`} style={iconStyle} alt={'cat icon'} /> 
          {wreck.loc_icons.map(icon =>(
            <img src={`${icon}`} style={iconStyle} alt={'loc icons'} key={icon.concat(wreck.id)} />
          ))}
          </span>
          </Link></h3>
      <div>Julkaistu: {cdate}, Muokattu: {cmodified}</div>
      <div dangerouslySetInnerHTML={{ __html: wreck.excerpt.rendered }}  key={`story_${wreck.id}`}/>
      </div>
    </Col>
    </Row>
    

  )


}

const Hylkylista =({ wrecklist})=>{

  const [page,setPage] = useState([])
  const [currentPageNum,setCurrentPageNum] =useState(1)
  //const [pageCount,setPageCount] = useState(null)
  //const [wrecksTotal,setWrecksTotal] =useState(null)
  //const [catList,setCatList]=useState(null)
  //const [locList,setLocList]=useState(null)

  //sortParams
  const [sortField,setSortField] =useState('title') 
  const [sortDirection,setSortDirection] = useState('nouseva')
  const [itemsPerPage,setItemsPerPage]= useState(20)
  const [showSpinner,setShowSpinner] = useState(false)
  const [data, setData] = useState(undefined);
  

  const fetchIds = wrecklist.length>0
    ?wrecklist.map(wreck =>wreck.id)
    .slice(currentPageNum===1?0:(currentPageNum-1)*itemsPerPage,wrecklist.length>=currentPageNum*itemsPerPage? currentPageNum*itemsPerPage:wrecklist.length) 
    :null

  /*const {fetchData, data, loading, error, headers } = useAxiosFetch({
    method: "GET",
    url: '/wp-json/wp/v2/ait-dir-item',
    params: {
      _fields: 'id,title,date,modified,excerpt,marker,cat_icon,loc_icons,meta.hylytnet_muut_nimet,featured_media,_links,_embedded',
      _embed: 'wp:featuredmedia',
      per_page: '22',
      include: fetchIds?fetchIds.join(','):null
    },
  })*/
  
 useEffect( ()=>{
  console.log('Hylkylista.useEffect([currentPageNum]) :start')
  const fetchData =async()=>{
    const fetchIds = wrecklist.length>0
      ?wrecklist.map(wreck =>wreck.id)
        .slice(currentPageNum===1
          ?0
          :(currentPageNum-1)*itemsPerPage,wrecklist.length>=currentPageNum*itemsPerPage
            ? currentPageNum*itemsPerPage
            :wrecklist.length
        )
      :null
    
    const newData =await hylkyservices.foo({
      baseURL: config.SERVER,
      method: "GET",
      url: '/wp-json/wp/v2/ait-dir-item',
      params: {
        _fields: 'id,title,date,modified,excerpt,marker,cat_icon,loc_icons,meta.hylytnet_muut_nimet,featured_media,_links,_embedded',
        _embed: 'wp:featuredmedia',
        per_page: '22',
        include: fetchIds?fetchIds.join(','):null
      },
    })
    setData(newData)

  }

  if(data && wrecklist.length>0 && currentPageNum >1){
    console.log('Hylkylista.useEffect([currentPageNum]) empty list, not doing anything')
    fetchData()
    
  }
 },[currentPageNum])

 useEffect( ()=>{
  console.log('Hylkylista.useEffect([wrecklist]) :start')
  const fetchData=async ()=>{
    const fetchIds = wrecklist.length>0
    ?wrecklist.map(wreck =>wreck.id)
    .slice(currentPageNum===1?0:(currentPageNum-1)*itemsPerPage,wrecklist.length>=currentPageNum*itemsPerPage? currentPageNum*itemsPerPage:wrecklist.length)
    :null
    const newData= await hylkyservices.foo({
      baseURL: config.SERVER,
      method: "GET",
      url: '/wp-json/wp/v2/ait-dir-item',
      params: {
        _fields: 'id,title,date,modified,excerpt,marker,cat_icon,loc_icons,meta.hylytnet_muut_nimet,featured_media,_links,_embedded',
        _embed: 'wp:featuredmedia',
        per_page: '22',
        include: fetchIds?fetchIds.join(','):null
      },
    })
    setData(newData)

  }
  setPage([])
  if(currentPageNum !==1){
    setCurrentPageNum(1)
  }else if(wrecklist.length>0){
    fetchData()
  }
  
 },[wrecklist])

  useEffect(() => {
    console.log('Hylkylista.useEffect([data]) :start')
    if  (data && data.length) {
      const pageNum=currentPageNum?currentPageNum:1
      if(pageNum===1){
        setPage([])
      }
      const newIds=wrecklist.map(wreck =>wreck.id).slice(pageNum===1
        ?0
        :(pageNum-1)*itemsPerPage,wrecklist.length>=pageNum*itemsPerPage
          ? pageNum*itemsPerPage
          :wrecklist.length)
      const newPage =  data.sort( (a,b) =>(newIds.indexOf(a.id) -newIds.indexOf(b.id) ))
      if(pageNum>1){
      setPage([...page,...newPage])
      }else{
        setPage(newPage)
      }
      //setPageCount(newPageCount)
      setShowSpinner(false)
      //console.log(data);
    } else {
      console.log('Hylkylista.useEffect([data]) :start ***empty dataset***' )
      setPage([]);
    }
  }, [data]);

 

  



  

  
  if(!wrecklist){
    return(<h2>Latautuu...</h2>)
  }

  return(
    <Container >
      {/* loading && <p>loading...</p>*/}
      <Stack gap={5} >
       
        <div> Esitetään {currentPageNum} sivua  {Math.ceil(wrecklist.length/itemsPerPage)}:sta sivusta</div>
        {/*<div>Järjestetty kentän: {sortField} mukaan, suuntaan: {sortDirection} </div>*/}
        <Row> 
          <Col> 
            Näytetään {page.length} kaikista {wrecklist.length} kohteesta
          </Col>
          {/*<Col>
           <SortForm
              sortField = {sortField}
              setSortField={setSortField}
              sortDirection={sortDirection}
              setSortDirection={setSortDirection}
            />
          </Col>*/}
       </Row>
       <hr/>
      
        {page.length>0?page.map(wreck => <HylkylistaItem wreck={wreck} key={wreck.id}/>):<Row></Row>}
        <Row>
        Näytetään {page.length} hylkykohdetta  {wrecklist.length} kohteesta
        </Row>
        <Row>
        {wrecklist.length>page.length
          ?<>{showSpinner
              ?<>Ladataan...</>
              :<button 
                style={{width:'350px'}}
                onClick={(e)=>{
                  setShowSpinner(true)
                  setCurrentPageNum(currentPageNum+1)
                  console.log('more results initiated')
                }}>Lisää hakutuloksia...</button>}</>
          :<></>
        }
      </Row>
      </Stack>

    </Container>    
  )
} 
Hylkylista.defaultProps = {
  category: 'ait-dir-item-category',
  id:19
};
export default Hylkylista