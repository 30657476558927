export  const renderCoordinate=(decimal,type)=>{
  let toret = ''
  let degrees = 0;
  let minutes = 0;
  //let seconds = 0;
  let direction = 'X';
  let d = Math.abs(decimal);
   //get degrees
   if(Number.isNaN(decimal) || Math.abs(decimal) > 180 || typeof $type ==='boolean' ) {
      return false;
    }
    //set direction; north assumed
    if(type && decimal < 0) {
      direction = 'S';
    }
    else if(!type && decimal < 0) {
      direction = 'W';
    }
    else if(!type) {
      direction = 'E';
    }
    else {
      direction = 'N';
    }
    degrees = Math.floor(d);
    minutes =Math.round(  (((d - degrees) * 60) + Number.EPSILON)*10000)/10000;
    toret = `${degrees}\u{b0}${minutes.toFixed(3)}' ${direction}`
    return toret;
}

export const renderCoordinates=(lat,lon) => {
  const latr=renderCoordinate(lat,true)
  const lonr= renderCoordinate(lon,false)
  return `${latr},${lonr}`
}

export const renderLatLng =(latlng)=>{
  const latr=renderCoordinate(latlng.lat,true)
  const lonr= renderCoordinate(latlng.lng,false)
  return `${latr}<br/>${lonr}`
}