import jsmap from './jsmap'
import { useEffect,useState } from 'react'
//import { ReactDOM } from 'react';
import { useOverlayOptions } from './useOverlayOptions'
import MapSidebar from './MapSidebar';
import hylkyservices from '../../services/hylkykortti';
import { useNavigate } from 'react-router-dom';


const WreckMap =({filteredWrecks,allWrecks,wreckId,fitMapToMarkers,setFitMapToMarkers,visibilityState})=>{

  const [map,setMap]=useState(null)
  const [clusterer,setClusterer]=useState(null)
  const [infoWindow,setInfoWindow] =useState(null)
  const [clickedMarker,setClickedMarker] =useState(null)
  const [showAllWrecks,setShowAllWrecks] =useState(false)
  //const [nawToWreck,setNavToWreck] = useState(null)
  //const [navipos,setNaviPos]=useState(null)
  const {setOlMap,overlays} =useOverlayOptions()
  const [mapLoadStatus,setMapLoadStatus] = useState({mapInit: false, clusterer: false, map: false, infoWindow: false })
  const [isVisibilityUpdated, setIsVisibilityUpdated] = useState(false);
  
  const handleVisibiliityUpdate = () => {
    console.log("handleContentUpdate()")
    setIsVisibilityUpdated(true);

    setTimeout(() => {
      setIsVisibilityUpdated(false);
    }, 500);
  };

  useEffect(()=>{
    handleVisibiliityUpdate()
  },[visibilityState])

  /*const navigate = useNavigate()

  useEffect(()=>{
    if(nawToWreck){
      navigate(nawToWreck)
    }
  },[nawToWreck])*/

  

  useEffect(()=>{
    const fetchMap = async ()=>{
      await jsmap.initMap(allWrecks,setClusterer,setMap,setInfoWindow,setClickedMarker)
    }
    if(!mapLoadStatus.mapInit && allWrecks.length>0){
      const newStatus = {...mapLoadStatus, mapInit: true}
      setMapLoadStatus(newStatus)
      fetchMap()
    }    
  },[allWrecks])

  useEffect(()=>{
    if (map && clusterer && infoWindow){
      const newStatus = {...mapLoadStatus, map: true, clusterer: true, infoWindow: true}
      setMapLoadStatus(newStatus)
    }

  },[map,clusterer,infoWindow])



  useEffect(()=>{
    /*const fetchMap = async ()=>{
      await jsmap.initMap(allWrecks,setClusterer,setMap,setInfoWindow,setClickedMarker)
    }
    if(!clusterer  && allWrecks.length>0){
      //fetchMap()
    }
    if(allWrecks.length>0){
      allWrecks.forEach(wreck => {
        wreck.gMarker && wreck.gMarker.setVisible(false || showAllWrecks )
      });
    }
    if(filteredWrecks.length >0 && !showAllWrecks){
      filteredWrecks.forEach(wreck =>wreck.gMarker && wreck.gMarker.setVisible(true))
    }
    clusterer && clusterer.repaint()*/
    console.log('test1:',mapLoadStatus, 'test2:',Object.values(mapLoadStatus).length)
    if(Object.values(mapLoadStatus).filter(val=>val).length===Object.values(mapLoadStatus).length){
      selecMapMarkersToShow(showAllWrecks)
    }
  },[wreckId,mapLoadStatus,filteredWrecks,showAllWrecks,fitMapToMarkers])

  /*useEffect(()=>{
    if(Object.values(mapLoadStatus).filter(val=>val).length===Object.values(mapLoadStatus).length){
      selecMapMarkersToShow(showAllWrecks)
    }
  },[])*/

  /*useEffect(()=>{
    console.log('WreckMap useEffect() [wreckId...] >>>>>>>>setwreckid',wreckId,allWrecks,map)
    if(wreckId>0 && allWrecks.length>0 && map ){
      console.log('>>>>>>>>we have map etc')
      const currentMarker =allWrecks.filter(marker=>marker.id===wreckId)
      .map(m =>(
        m.gMarker
      ))
      //ei kartalle vaan tälle kompoenentille
      map.setCenter(currentMarker[0].getPosition())
      map.setZoom(14)
      infoWindow.setContent(currentMarker[0].content)
      fetchInfoWindowExtraContent(wreckId)
      infoWindow.open(map,currentMarker[0]);
      //currentMarker[0].activate=true
    }else{
      //map && map.setZoom(4)
      clusterer && clusterer.fitMapToMarkers()
    }


  },[wreckId,map,clusterer,allWrecks,infoWindow])*/


  useEffect(()=>{
    if(map){
      jsmap.hylytnet_Navigation(map)
      setOlMap(map)
    }
  },[map])

  useEffect(()=>{
    if(map && overlays.length>0){
      //map.overlayMapTypes.setAt(0,overlays[1])

     // map.overlayMapTypes.setAt(0,overlays[2])
    }
  },[overlays])

  useEffect(()=>{
    console.log('useEffect(),[clickedMarker,infoWindow] clikedmarker:',clickedMarker,'infowindow', infoWindow)
    if(clickedMarker){
      fetchInfoWindowExtraContent(clickedMarker)
    }
    if(clickedMarker && infoWindow.content === clickedMarker.content ){
      //infoWindow.setContent(infoWindow.content.concat('<div>Hello world</div>'))
      //infoWindow.setContent(infoWindow.content.concat(ReactDOM.render(infoContent)))
    }
  },[clickedMarker,infoWindow])

  const fetchInfoWindowExtraContent=  (marker)=>{

      const fetcData = async ()=>{
        const id = marker.id
        const newPage = await hylkyservices.getPage(id)
        //console.log('newPage', newPage)
        const extraInfo = (newPage.meta.Kohde_Alus_rakennusmateriaali && newPage.meta.Kohde_Alus_rakennusmateriaali.length
        ?`<strong>Materiaali:</strong>${newPage.meta.Kohde_Alus_rakennusmateriaali[0]}<br/>`
        :'')
        .concat( 
          newPage.meta.Kohde_Hylky_Osa_pohjansyvyys.length
          ?`<strong>Pohjan syvyys:</strong>${newPage.meta.Kohde_Hylky_Osa_pohjansyvyys[0]}<br/>`
          :'')
        .concat(
        newPage.meta.Kohde_Hylky_Osa_minimisyvyys.length
        ?`<strong>Minimisyvyys:</strong>${newPage.meta.Kohde_Hylky_Osa_minimisyvyys[0]}<br/>`
        :'')
        .concat(
          newPage.meta.Kohde_Hylky_Osa_pituus.length
            ?`<strong>Pituus:</strong>${newPage.meta.Kohde_Hylky_Osa_pituus[0]}<br/>`
            :''
        )
        .concat(
          newPage.meta.Kohde_Uppoaminen_aika.length
            ?`<strong>Uppoamisaika:</strong>${newPage.meta.Kohde_Uppoaminen_aika[0]}<br/>`
            :''
        )
        .concat(
          newPage.meta.Kohde_Alus_tyyppi.length
            ?`<strong>Tyyppi:</strong>${newPage.meta.Kohde_Alus_tyyppi[0]}<br/>`
            :''
        )
        marker.extra = "".concat(extraInfo)
        infoWindow.setContent(marker.content.concat(extraInfo))
      }
      if(!marker.extra){
         fetcData()
      }else{
        infoWindow.setContent(marker.content.concat(marker.extra))
      }
  }

  const selecMapMarkersToShow=(showAll)=>{
    if(infoWindow && !wreckId){
      infoWindow.close()
    }
    allWrecks.forEach(wreck => {
      wreck.gMarker && wreck.gMarker.setVisible(false || showAll)
    })
    if(!showAll){
      filteredWrecks.forEach(wreck =>wreck.gMarker && wreck.gMarker.setVisible(true))
    }
    if(wreckId !==0 ){
        const currentMarker =allWrecks.filter(marker=>marker.id===wreckId)
        .map(m =>(
          m.gMarker
        ))
      map.setCenter(currentMarker[0].getPosition())
      map.setZoom(14)
      infoWindow.setContent(currentMarker[0].content)
      infoWindow.open(map,currentMarker[0])
      fetchInfoWindowExtraContent(currentMarker[0])
    }
    if(fitMapToMarkers){
      if(wreckId ===0){
        clusterer && clusterer.fitMapToMarkers()
      }
      setFitMapToMarkers(false)

    }
    clusterer && clusterer.repaint()
  }
  
  if(!filteredWrecks ){
    return(<div>Loading...</div>)
  }

  return(
    <div>
      <input type='hidden' id="dir-searchinput-geo-lat"/>
      <input type='hidden' id="dir-searchinput-geo-lng"/>
    {/*<button onClick={()=>{map.overlayMapTypes.clear()}}>Tyhjennä</button>*/}
    <div id='map' className={`${isVisibilityUpdated && visibilityState?'updated-height-shrink':isVisibilityUpdated && !visibilityState? 'updated-height-grow':visibilityState?'hidden':'mapContainer'}`}>
    {map && setOlMap &&overlays.length>0 && allWrecks && filteredWrecks
      ?<MapSidebar
        map={map}
        overlays={overlays}
        showAllWrecks={showAllWrecks}
        setShowAllWrecks={setShowAllWrecks}
        wreckId={wreckId}
        showToggleControl={true}
      />
      :null}
    </div>
     

    
    
    </div>
  )
}

export default WreckMap