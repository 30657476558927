import { useEffect, useState } from "react"
//import { useParams } from "react-router-dom"
//import { Container, Row } from "react-bootstrap"
//import {Container,Row,Col} from 'react-bootstrap'
import hylkyservice from '../services/hylkykortti'
//import ContactForm from "./ContactForm"
//import ReportNewWreckForm from "./ReportNewWreckForm"
//import Sanasto from "./Sanasto"


const Footer = ({id}) =>{

  //const[pageId,setPageId] = useState(0)
  const [page,setPage] = useState(null)
  //const id = useParams().id


 

  useEffect( ()=>{
    const fetchData = async ()=>{
      if(id>0){
        //setPageId(id)
        const newPage = await hylkyservice.getWPPage(id)
        //newPage =newPage.replace('[tabs]','<ul class="nav nav-tabs">')
        //newPage = newPage.replace('[tab title=”', )
        //pgrep = /\[tab title=\”C\”\]/ig


        setPage(newPage)
      }else{
        setPage(null)
      }
    }
    fetchData()
  },[id])

  if(!page || page ===undefined){
    return(
      <div>loading...</div>
    )
  }


  return (
    <div>
     <p dangerouslySetInnerHTML={{ __html: page.content.rendered }} />
    </div>
  )


}
export default Footer