import axios from 'axios'

const baseurl= '/map.json'
//'http://localhost:8080/wp-json/wp/v2/ait-dir-item/?_fields=id,title,kohdetyyppi,kohdeluokitus,meta.hylytnet_muut_nimet,meta.gpsLatitude,meta.gpsLongitude,marker&per_page=3000'
//const baseurl='http://localhost:8080/wp-json/map/v1/markers'

const getMarkers= async ()=>{
  const responce =await axios.get(baseurl)
  //console.log('Markkinat',responce.data)
  return responce.data

}

export default(
  getMarkers
)