import { useState,useEffect } from "react"
import { useCombobox } from "downshift"
import { useNavigate } from 'react-router-dom'

import { InputGroup,FloatingLabel,Form } from "react-bootstrap"

const SearchComboBox =({filteredWrecks,searchString,setSearchString})=>{

  const [items,setItems] =useState([])
  const[curentItem,setCurrentItem] = useState(null)
  const navigate = useNavigate()

  //console.log('markerdata',markerdata)

  /*const handleSelection=(wreckid) =>{
    navigate(`/hylkykortti/${wreckid}`)
  }*/

   useEffect(()=>{
    if(filteredWrecks && filteredWrecks.length >0){
    const sliceCount =filteredWrecks.filter(marker=>marker.indexPoints<30000).length
    setItems(filteredWrecks.slice(0,Math.max(sliceCount,30)).map(marker=>{
      return({
        ...marker,
        value: marker.title.rendered
      })
    }))
    setCurrentItem(null)
    }else{
      console.log('SearchComboBox.useEffect(),[markerdata] -no markers, doing nothing')
    }
  },[filteredWrecks])

  useEffect(()=>{
    if(searchString===undefined){
      reset()
    }
  },[searchString])

  const renderInputValue=(item)=>{
    return (item
    ?item.meta['hylytnet_muut_nimet'].length===0
      ?item.title.rendered
      :item.title.rendered.concat(' (').concat(item.meta['hylytnet_muut_nimet'].join('')).concat(')')
    :'') 

  }


  const {
    inputValue,
    selectedItem,
    isOpen,
    //getToggleButtonProps,
    //getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    getInputProps,
    getComboboxProps,
    setState,
    reset,
  } = useCombobox({
    items,
    onInputValueChange({inputValue}) {
      //setItems(books.filter(getBooksFilter(inputValue)))
      if(inputValue !==undefined){
        console.log('onInputValueChange',inputValue)
        if(inputValue && !inputValue.includes("(") ){
          setSearchString(inputValue)
          if(filteredWrecks && filteredWrecks.length>1){
            navigate(`hylkylista`)
          }
          //filteredWrecks.length===1 ? navigate(`hylkykortti/${filteredWrecks[0].id}`):navigate(`hylkylista`)
        }
        /*if(markerdata.length>1){
          navigate(`hylkylista`)
        }*/
      }
    },
    onSelectedItemChange(changes){
      console.log('onSelectedItemChange',changes)
      if(changes.selectedItem){
        navigate(`/hylkykortti/${changes.selectedItem.id}`)
      }
      //setSearchString('')
    },
    itemToString(item) {
      return renderInputValue(item)
    },
  })

  const clearMe = ()=>{
    setSearchString(undefined)
    reset()
  }

  useEffect(()=>{
    if(!searchString || searchString.length===0){
      //inputValue=''

      //onInputValueChange('')
    }
  },[searchString])



  return (
  <div>
    <InputGroup className=""  {...getComboboxProps()}>
          <InputGroup.Text id="inputGroupPrepend" 
            style={{
              backgroundImage: `url("/Vector_search_icon.svg")`, 
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain', 
              backgroundPosition: 'center center',
             
            }}
            > { }</InputGroup.Text>
            <FloatingLabel  label="Etsi  hylkynimellä..." >
              <Form.Control type="text" style={{width: '360px', display: 'inline'}} className="gx-6" {...getInputProps()}/>
              {inputValue? <span  style={{backgroundColor: '#e9ecef', border: '1px solid #ced4da', borderRadius: '0.25rem', lineHeight: '1.5',padding: '1rem 0.75rem', paddingTop: '1.625rem'}} onClick={()=>{clearMe()}}>×</span>:null}

          </FloatingLabel>
      </InputGroup>
     

<ul {...getMenuProps({
  style: isOpen?{    
    listStyle: 'none',
    zIndex: 1,
    position: 'absolute',
    border: '1px solid grey',
    borderRadius: '0.5em',
    padding:'1em',
    backgroundColor: 'white',
    maxHeight: '20em'
  }:{}
})} className="overflow-scroll" >
  {isOpen
    ? items
        .filter(item=>item.id !==curentItem)
        .map((item, index) => (
          <li 
            {...getItemProps({
              key: item.id,
              index,
              item,
              id: item.id,
              style: {
                backgroundColor:
                  highlightedIndex === index ? 'lightgray' : 'white',
                fontWeight: selectedItem === item ? 'bold' : 'normal',
                borderBottom: '1px dotted lightgrey'
  
              },
            })}
          >
            {item.title.rendered}  { item.meta['hylytnet_muut_nimet'].length===0? '':'('.concat(item.meta['hylytnet_muut_nimet'].join(',')).concat(')')}
          </li>
        )).concat(items.length===filteredWrecks.length?'':`${filteredWrecks.length-items.length} more...`)
      
    : null}
   
</ul>
</div>
  )
}
export default SearchComboBox