
import { useState,useEffect } from "react";

 export const useOverlayOptions =()=>{


  const [overlays,setOverlays]=useState([])
  const [olMap,setOlMap]=useState(null)

   useEffect(()=>{
    const initLayers= async()=>{
        
      const ppkMapType = new window.google.maps.ImageMapType(mmlMapTypeOptions);
      ppkMapType.title='Peruskartta'
      ppkMapType.index=0
      const ppksel= await window.localStorage.getItem(mmlMapTypeOptions.name)
      ppkMapType.selected= ppksel==='true'
      if(ppkMapType.selected){
        olMap.overlayMapTypes.push(ppkMapType)
      }
      var vaylaMapType = new window.google.maps.ImageMapType(vaylaMapTypeOptions);
      vaylaMapType.title='Merikartta'
      vaylaMapType.index=0
      const vaylasel = await window.localStorage.getItem(vaylaMapTypeOptions.name)
      vaylaMapType.selected= vaylasel==='true'
      if(vaylaMapType.selected){
        olMap.overlayMapTypes.push(vaylaMapType)
      }

      const  osmMapType = new window.google.maps.ImageMapType(osmMapTypeOptions);
      osmMapType.title='Open Sea Maps';
      osmMapType.index=1;
      const osmsel= await window.localStorage.getItem(osmMapTypeOptions.name)
      osmMapType.selected=osmsel==='true'
      if(osmMapType.selected){
        olMap.overlayMapTypes.push(osmMapType)
      }


      const googlemaps = {
        title: 'Google Maps',
        name:'Google Maps',
        index: 0,
        selected:  !(vaylaMapType.selected || ppkMapType.selected)
      }
      console.log('initvals:', [osmMapType,ppkMapType,vaylaMapType,googlemaps])
      setOverlays([osmMapType,ppkMapType,vaylaMapType,googlemaps])

    }

  if(olMap){
     initLayers() 
  }
},[olMap]) //eslint-disable-line


  const  osmMapTypeOptions = {
    getTileUrl: function(coord, zoom) {
      //console.log("getTileUrl("+coord.x+","+coord.y+","+zoom+")");
      var z = zoom;
      var limit = Math.pow(2, z);
      if (coord.y < 0 || coord.y >= limit ) {
        return null;
      } else {
        coord.x = ((coord.x % limit) + limit) % limit;
        var url = "https://t1.openseamap.org/seamark/";
        var path = `${zoom}/${coord.x}/${coord.y}.png`;
        //console.log("getTileUrl:" + url + path);
        return url + path;
        
      }
    },
    tileSize: {width: 256, height: 256},//new window.google.maps.Size(256, 256),
    isPng: true,
    maxZoom: 19,
    minZoom: 0,
    name: "OSM"
  };
  /*
  function getTileURL(bounds) {
    var res = olMap.getResolution();
    var x = Math.round((bounds.left - this.maxExtent.left) / (res * this.tileSize.w));
    var y = Math.round((this.maxExtent.top - bounds.top) / (res * this.tileSize.h));
    var z = olMap.getZoom();
    var limit = Math.pow(2, z);
    if (y < 0 || y >= limit) {
      return null;
    } else {
      x = ((x % limit) + limit) % limit;
      let url = this.url;
      let path = z + "/" + x + "/" + y + "." + this.type;
      if (url instanceof Array) {
        url = this.selectUrl(path, url);
      }
      return url + path;
    }
  }*/

  
 

/// OSM Marine overlay

const  mmlMapTypeOptions = {
  getTileUrl: function(coord, zoom) {
    //console.log("getTileUrl("+coord.x+","+coord.y+","+zoom+")");
    var z = zoom;
    var limit = Math.pow(2, z);
    //console.log("getTileUrl("+coord.x+","+coord.y+","+zoom+","+coord.x/limit+","+coord.y/limit+")");
    if (coord.y < 0 || coord.y >= limit ||zoom < 7 || zoom >20 ||coord.x/limit < 0.53125  || coord.x/limit >0.591796875 ||coord.y/limit >0.35 ||coord.y/limit <0.21484375) {
      return null;
    } else {
      coord.x = ((coord.x % limit) + limit) % limit;
      const url = "https://tiles.kartat.kapsi.fi/peruskartta/";
      const path = `${zoom}/${coord.x}/${coord.y}.png`;
      //console.log("getTileUrl:" + url + path);
      return url + path;
      
    }
  },
  tileSize: {width: 256, height: 256},//new window.google.maps.Size(256, 256),
  isPng: true,
  maxZoom: 20,
  minZoom: 7,
  name: "MML"
};

const  vaylaMapTypeOptions = {  
  getTileUrl: function(coord, zoom) {
    var s  = Math.pow(2, zoom);  
    var twidth = 256;
    var theight = 256;
    if( zoom < 10){
      return null;
    }	
    //console.log("zoom: " +zoom);
    //latlng bounds of the 4 corners of the google tile
    //Note the coord passed in represents the top left hand (NW) corner of the tile.
    var gBl = olMap.getProjection().fromPointToLatLng(
      new window.google.maps.Point(coord.x * twidth / s, (coord.y + 1) * theight / s)); // bottom left / SW
      var gTr = olMap.getProjection().fromPointToLatLng(
        new window.google.maps.Point((coord.x + 1) * twidth / s, coord.y * theight / s)); // top right / NE 
        // Bounding box coords for tile in WMS pre-1.3 format (x,y)
        //var bbox = gBl.lng() + "%2C" + gBl.lat() + "%2C" + gTr.lng() + "%2C" + gTr.lat();
        var gBlLng=gBl.lng() * 20037508.34 / 180;
        var gTrLng=gTr.lng() * 20037508.34 / 180;
        var gBlLat=Math.log(Math.tan((90 + gBl.lat()) * Math.PI / 360)) / (Math.PI / 180);
        gBlLat=gBlLat* 20037508.34 / 180;
        var gTrLat=Math.log(Math.tan((90 + gTr.lat()) * Math.PI / 360)) / (Math.PI / 180);    
        gTrLat=gTrLat* 20037508.34 / 180;
        var bbox = gBlLng+","+gBlLat+","+gTrLng+","+gTrLat;
        var url = "https://julkinen.traficom.fi/s57/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=cells&SRS=EPSG%3A3857&TILED=true&WIDTH=256&HEIGHT=256&STYLES=&BBOX="+bbox;
        return url;
        
      },
      tileSize: {width: 256, height: 256},//new window.google.maps.Size(256, 256),
      isPng: true,
      maxZoom: 20,
      minZoom: 7,
      name: "VAYLA"
};

return {overlays,setOlMap}

}