import { useEffect,useState } from "react"
import { Link,NavLink } from "react-router-dom"
import getMenu from "../services/menu"
import { Navbar,Nav,Container } from "react-bootstrap"
import '../App.css'


const Menu = () =>{
  const [menuItems,setMenuItems] = useState([])
  const padding = {
    padding: 5
  }


  useEffect( ()=>{

  const fetchData = async ()=>{
    if(menuItems.length===0){
        const menuShcema = await getMenu()
        const foo = menuShcema.items.map(item =>(
         {
            menu_id: item.ID,
            title: item.title,
            order: item.menu_order,
            target_type: item.object,
            target_id: item.object_id, 
            url: item.object==='page' ? `/page/${item.object_id}`: item.url,
            type: item.type
          } 
        ))
        setMenuItems(foo)
    }
  }
  fetchData()
    

  },[]) // eslint-disable-line


  return(
    <nav>
      <Navbar expand="lg" className="navbar-right" >
        <Container>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav"> 
              <Nav className="ms-auto">
              <Nav.Link href="#" as="span">
                {menuItems.map(menuItem => 
                  {
                    if(menuItem.type !== 'custom'){
                      return (
                        <NavLink 
                            style={padding}  
                            key={menuItem.menu_id} 
                            to={menuItem.url}
                            relative={menuItem.url}
                            className={({ isActive, isPending }) =>
                            isPending ? "pending" : isActive ? "active" : ""
                          }
                        > 
                            {menuItem.title} 
                        </NavLink>

                      )
                    } else {
                        return  (    
                          <a href = {menuItem.url} >
                            {menuItem.title}
                          </a>
                        )
                    }
                  }
                )} 
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </nav>
  )

}
export default Menu