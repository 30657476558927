import { useState,useEffect } from "react"
import {Container,Row} from 'react-bootstrap'
import hylkyservices from "../services/hylkykortti";
import 'bootstrap/dist/css/bootstrap.min.css';


const Comment =({comment}) =>{
  const cdate = (new Date(comment.date)).toLocaleString('fi-FI')
  return(
    <div key={comment.id}>
      <h3>{comment.author_name}</h3>  {cdate}
      <div><img src={comment.author_avatar_urls[48]} alt="Avatar"/></div>
      <div dangerouslySetInnerHTML={{ __html: comment.content.rendered}} />
    </div>
  )
}

const Comments =({page})=>{
  const [comments,setComments] = useState([])

  useEffect(()=>{
    const fetchComments= async () =>{
     const newPage= await hylkyservices.getComments(page.id)
      setComments(newPage)
      //setTitle(newPage.json().title.renderer)
      //console.log('page  is:',newPage.id)
    }
    fetchComments()
    
  },[page.id])

  //if(comments)

  return(
    <Container>
      <Row>
        <h2>Kommentit</h2>
        { comments.length===0
          ?<div>Ei kommentteja</div>
          :<div>{comments.map(row => (<Comment key={row.id} comment={row}/>))}</div>
        }     
        
      </Row>
    </Container>
  )

  

}
export default Comments