const YoutubeFeatured =({youtubeCode,authors,story}) =>{

  const link = `https://www.youtube.com/embed/${youtubeCode}?rel=0&vq=hd1080`
  
  return(
    <div>
      <iframe 
        title={"Video"} 
        frameBorder={0} 
        height={360} 
        width={window.screen.width>640?640:(window.screen.width*0.9).toFixed(0)} 
        src={link}
        allowFullScreen
         
        ></iframe>
        <div><strong>Tekijä tai tekijät:</strong>{ }{authors}</div>
        <div>{story}</div>
        <hr/>
    </div>
  )  

}

export default YoutubeFeatured