import { useState,useEffect } from "react"
import { Stack,Container} from "react-bootstrap"
import { useNavigate } from "react-router-dom"

const PositionPrecisionFilter =({setPresicion,presicion,categoryCatalog,wreckTypeFilters,label,setPath,pathOrder,handleContentUpdate})=>{

  /*const gpsCats =[24,79,73,74]
  const aboutCats =[33,49 ,73 ]
  const rough =[28,42,75 ]
  const veryRough = [26,67,77]*/

  const greendot= {
    height: '10px',
    width: '10px',
    backgroundColor: "#86c316",
    borderRadius: '50%',
    display: 'inline-block',
  }

  const bluedot = {
    height: '10px',
    width: '10px',
    backgroundColor: "#59c5e9",
    borderRadius: '50%',
    display: 'inline-block'
}

const reddot ={
  height: '10px',
    width: '10px',
    backgroundColor: '#d82a70',
    borderRadius: '50%',
    display: 'inline-block'
}

const greydot ={
  height: '10px',
  width: '10px',
  backgroundColor: '#cfcfcf',
  borderRadius: '50%',
  display: 'inline-block'
}

const notSelectedType= {
  border: '1px solid #70685a',
  borderRadius: '15px',
  padding: window.innerWidth > 806?'5px':'5px',
  paddingLeft: window.innerWidth > 806?'25px':'25px',
  minWidth: '40px',
  minHeight: '40px',
  backgroundColor: '#8c8271',
  boxShadow: '0 1px 0 #9e9687 inset, 0 1px 0 #565046',
  marginBottom: '1rem',
  backgroundSize: window.innerWidth > 806?'15px 15px':'25px 25px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '5px center'
  
}

const selectedStyle={
  border: '1px',
  borderColor: 'black',
  borderStyle: 'double',
  borderRadius: '15px',
  minWidth: '40px',
  minHeight: '40px',
  padding: window.innerWidth > 806?'5px':'5px',
  paddingLeft: window.innerWidth > 806?'25px':'25px',
  backgroundColor: '#eaecef',
  marginBottom: '1rem',
  backgroundSize: window.innerWidth > 806?'15px 15px':'25px 25px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '5px center'
}
  const navigate = useNavigate()



  const [checked,setChecked]= useState([
    {sname:" 50m", name:'GPS (0-50 m)', value: false, controller_for: '-gps', style: greendot, icon: '/img/greendot.svg'},
    {sname:" 1km", name:'Noin (50 m - 1 km)',value: false, controller_for: '-noin', style: bluedot,icon: '/img/bluedot.svg' },
    {sname:" 5km", name:'Karkea (n. 1-5 km)',value: false, controller_for: '-karkea', style: reddot,icon: '/img/reddot.svg' },
    {sname:" > 5km", name: 'Erittäin karkea', value: false, controller_for: '-erittainkarkea', style: greydot,icon: '/img/greydot.svg'}
  ])

  useEffect(()=>{
    if(checked.length>0){
      const str=checked.filter(check=>check.value).map(c=>c.name)
      const foo= str?{name: label, selection: str,pathOrder}: {name: label, selection: [],pathOrder}
      console.log('olio on:',foo)
      setPath(foo)
    }
  },[checked])

  useEffect(()=>{
    if(presicion && presicion.length ===0 && checked.length){
      const trueval = checked.find(item=>item.value) //some value is true and reset called
      if(trueval){
        //setChecked([])
        const newObj= checked.map(item=>({ ...item, value: false}))
        setChecked(newObj)
      }
    }
    
  },[presicion])

  /*
  const [value,setValue] =useState([false,false,false])
  const handleChange = (val) =>{ 
    setValue(val)
    console.log('tapahtuma valuella:',val)
  }*/

  

  const handleFilterSelection =(e)=>{
    const newObj= checked.map(item=>(item.controller_for!==e.target.id
        ?item
        :{ ...item, value: !item.value})
    )
    console.log('foo',checked,e,'newobj:',newObj)
    setChecked(newObj)
    const ids=makePrecisionArray(categoryCatalog,newObj)
    setPresicion(ids)
    navigate('hylkylista')
    //handleContentUpdate()
  }
  

  const makePrecisionArray =(allCategoriesList, controllers)=>{
    const chosenAccuracies =controllers.filter(controller =>controller.value).map(c=>c.controller_for) //['-gps','-noin']
    const idsToShow= allCategoriesList.filter(catFromAll=>
      chosenAccuracies.some(cat =>catFromAll.slug.replace('erittain-karkea','erittainkarkea').endsWith(cat)))
      .map(a=>(a.id))
      
    return idsToShow
  }



  return(
   
   
    <Container className="positionPrecisionFilter" fluid>
      <label>{label}</label>
      <Stack direction="horizontal" gap={window.innerWidth > 806?3:2}>
        {checked.map((check,idx)=>(
          <button
          key={check.controller_for}
          value={check.value}
          id={check.controller_for}
          style={check.value
            ?{...selectedStyle,backgroundImage: `url(${check.icon})`}
            :{...notSelectedType, backgroundImage: `url(${check.icon})`}
          }
          onClick={(e)=>handleFilterSelection(e)}
          >{/*<span style={check.style}></span>*/}
          {window.innerWidth > 806?check.name:check.sname}{ check.value?' ×':null}</button>
        ))}
       
      </Stack>  

      </Container>
      
  )
  
} 
export default PositionPrecisionFilter