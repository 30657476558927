import { useState,useEffect } from "react"
import { useParams } from "react-router-dom";
import hylkyservices from "../services/hylkykortti";
import {Container,Row,Col} from 'react-bootstrap'
import Comments from "./Comments";
import Media from "./Media"
import 'bootstrap/dist/css/bootstrap.min.css';
import { renderCoordinate } from "../utils/utils";
import YoutubeFeatured from "./YoutubeFeatured";
import Sketchfabs from "./Sketchfabs";

/*
const OldLinja =({linja,mk})=>{

  return(
    <>
    {linja.length ===0
      ?<></>
      :<>
        <h3>Linjapiirros</h3>
        {linja.map(lk =>(<Row><img src={lk} /></Row>))}
      </>
    }
    {mk.length ===0
      ?<></>
      :<>
        <h3>Merikortti</h3>
        {mk.map(mkImg =>(<Row><img src={mkImg} /></Row>))}
      </>
    }
  )
  </>

}*/

const Hylkykortti = ({id}) =>{
  const [page,setPage]= useState(null);
  const [layout,setLaout] = useState(null);
  //const [media,setMedia]= useState([]);
  const [linja,setLinja] = useState([]);
  const [mk,setMk] = useState([]);
  const [media,setMedia] = useState([])
  const [threeDModel,setThreeDModel] = useState([])
  const [oembed,setOembed] = useState([])
  const [video,setVideo] = useState([])

  


  //const id = useParams().id

  /*const fbStyle={
    border: 'none',
    overflow: 'hidden',
    width: '113px',
    height: '21px'
  }*/

  //const [response,setResponce]=useState(null)

  const iconStyle ={
    width: '35px',
    height: '35px'
  }


  const styleH ={
      border: 'none',
      borderTop: '3px double #333',
      color: '#333',
      overflow: 'visible',
      textAlign: 'center',
      height: '5px',
  }

  useEffect(()=>{
    const fetchPage= async () =>{
      //const wreckId =id>0?id:519
     const newPage= await hylkyservices.getPage(id)
      setPage(newPage)
      //setTitle(newPage.json().title.renderer)
      //console.log('page  is:',newPage.id)
    }
    fetchPage()
    
  },[id])

  useEffect(()=>{
    const fetchLayout= async () =>{
      const newPage= await hylkyservices.getPageLayout('wreckcard')
       setLaout(newPage)
       //console.log(newPage)
     }
     fetchLayout()
     //const fobba = JSON.parse(foo)
     //setLaout(fobba.wreckcard_display)

  },[])


  useEffect(()=>{
    const fetchImages= async () =>{
     const newPage= await hylkyservices.getMedia(page.id)
     //console.log(newPage)
      const newFoo = newPage.filter(media => (media.meta._wp_attachment_image_type==='kuvitus')).map(row=>{
        return {
          //id: row.id,
          original: row.media_details.sizes.full.source_url,
          thumbnail: row.media_details.sizes.thumbnail.source_url,
          originalTitle: row.title.rendered,
          title: row.title.rendered, 
          description: row.caption.rendered
        }
      })
      setMedia(newFoo)
      const newLinja =newPage.filter(media => (
        media.meta._wp_attachment_image_type==='linja'
      ))
      .map(row=>{ 
        return {
          original: row.guid.rendered,
          thumbnail: row.media_details.sizes.thumbnail.source_url,
          originalTitle: 'Linjapiirros',
          description: 'Linjapiirros',
        }
      })
      setLinja(newLinja)
      const newMk = newPage.filter(media =>(
        media.meta._wp_attachment_image_type==='mk' 
      ))
      .map(row=>{ 
        return {
          original: row.guid.rendered,
          thumbnail: row.media_details.sizes.thumbnail.source_url,
          originalTitle: 'Merikortti',
          description: 'Merikortti'
        }
      })
      setMk(newMk)
      setLinja([...newLinja, ...newMk])
      const newModels =newPage.filter(media =>(
        media.meta._wp_attachment_image_type==='3dmalli' 
      ))
      .map(row=>{ 
        return {
          id: row.id,
          url: row.guid.rendered,
          title: row.title.rendered,
          caption: row.caption.rendered 
        }
      }) 
      setThreeDModel(newModels)

      const newoembed =newPage.filter(media => (
        media.meta._wp_attachment_image_type==='oembed'
      ))
      .map(row=>{ 
        return {
          id: row.id,
          url: row.guid.rendered,
          title: row.title.rendered,
          caption: row.caption.rendered 
        }
      })
      setOembed(newoembed)
      const newVideos =newPage.filter(media => (
        media.meta._wp_attachment_image_type==='video'
      ))
      .map(row=>{ 
        return {
          id: row.id,
          url: row.guid.rendered.replace('watch?v=','embed/'),
          title: row.title.rendered,
          caption: row.caption.rendered,
        }
      })
      setVideo(newVideos)
      
    }
    if(page!==null){
      fetchImages()
    }
  },[page])

  
  //metadatakentistä luettavat sisällöt
  const getContent=(display)=>{
    if(page === null || layout ===null){
      return (
        <div>no content</div>
      )
    }
    //fetch layout json
    const fields =layout.filter(layout =>(layout.key ===display)).map(contentrows =>contentrows.fields)[0]
    fields.forEach(row => {
      if(row.key  !== undefined && page.meta[row.key] !==undefined){
        console.log('käsitellään', page.meta[row.key],row.key )
        const zerosOut=page.meta[row.key].filter(theval=>(theval.length>0))
        row.value = zerosOut//page.meta[row.key] !==null?page.meta[row.key]:[]
      }else{
        row.value=[]
      }
      if(row.keys){
        //find values from page meta whose keys are in this field
        row.values = []
        row.keys.forEach(key=>{
          row.values = [...row.values,page.meta[key][0] ]
        })
        //row.values = values
        //console.log('values are:', row.values)

      }
    });

    return fields.map(({key,label,value,type,keys,values,element})=>{
      switch (type){
        case 'location':
          return (
            <div key={keys[0]}>
              <strong>{label}</strong> {renderCoordinate(values[0],true)}
              , {renderCoordinate(values[1],false)} {values[2]}</div>
           )
        case 'video_featured':
          if(keys.length===0 || values.length===0){
              return null
          }
          if(!values[0]){
            return null
          }
          return(
            <div key={keys[0]}>
              <YoutubeFeatured
              youtubeCode={values[0]}
              authors={values[1]}
              story={values[2]}
              />
            </div>
          )        
        case 'xmlitems':
          if(value.length===0){
            return null
          }
          const regex = element?`/${element}>/ig`:/linkki>/ig
          //console.log('value on:', value[0])
          const li_values = value[0].replace(regex, 'li>')
          return (
            <>
            <strong>{label}</strong>
            <ul dangerouslySetInnerHTML={{ __html: li_values }} />
            </>
          )
        case 'items':
          const li = value.join('<br/>')
          return(
            <>
            <strong>{label} </strong>
            <div dangerouslySetInnerHTML={{ __html: li }} />
            </> 
          )

        default:
          const separator = type==='rows'?'<br/>':', '
          return value.length===0?<></>:<div id={key}> <strong>{label}</strong> { value.join(separator) }</div>  
      }
    })

   
    /*return(
      <div>
      {
        fields.map(({key,label,value,type})=>{ 
          const separator = type==='rows'?'<br/>':', '
         return value.length===0?<></>:<div id={key}> <strong>{label}</strong> { value.join(separator) }</div>
          
        })
      }
      </div>
    )*/
  }
  
  if(page === null || layout ===null){
    return (
      <div>no content</div>
    )
  }
  const mjUrl=(id)=>( 'https://www.kyppi.fi/to.aspx?id=112.'.concat(id))

  //getContent('story')

  return(
    <div>
    <Container>
    <Row className="justify-content-md-center">
      <Col>
      <h2 className="text-center">{page.title.rendered}</h2>
      </Col>
      </Row>
      <Row>
        <Col>
      <p key={`content_${page.id}`} dangerouslySetInnerHTML={{ __html: page.content.rendered }} />
      
      <div>
        {getContent('story')}
      </div>
      </Col>
        <Col>
        <div>
          <div><img src={`${page.cat_icon}`} style={iconStyle} alt="category_marker"/> 
          {page.loc_icons.map(icon =>(
            <img src={`${icon}`} style={iconStyle} alt="article_type"/>
          ))}
          </div>
          {getContent('story_right')}
        </div>
        <div>{getContent('story_right_bottom')}</div>
      </Col>
      </Row>
    </Container>
    <hr style={styleH}/>
    <Container>
      <Row>
        <Col>
          <h2>Hylkytiedot</h2>
        </Col>
      </Row>
      <Row>
        <Col>
        <div>{getContent('wreckinfo')}</div>
       
        </Col>
        <Col>
          {page.meta['hylytnet_kohde_mjid'] !==null && page.meta['hylytnet_kohde_mjid'].length >0 
              ?<Row><a href={mjUrl(page.meta['hylytnet_kohde_mjid'])}  target="_blank" rel="noopener noreferrer" >
                MJ-REKISTERI »
                </a> 
              </Row>
              :<></>
          }
          {linja.length>0 || mk.length>0?<Media 
            page={page} 
            media={linja} 
            showBullets ={linja.length >1}
            showThumbnails ={linja.length >1}
            showPlayButton ={linja.length>1} 
            thumbnailPosition={'left'}
          />:null}
        </Col>
      </Row>
    </Container>
    <hr></hr>
    {  
    threeDModel.length>0 ||
    media.length>0 ||
    oembed.length>0 ||
    video.length>0 ||
    page.meta['hylytnet_facebook_url'].length >0 ||
    page.meta['Kohde_Video_youtube_code'].length >0 
    ?<Container>
    <h2>Media</h2>
      {threeDModel.length>0?<div><Sketchfabs  models={threeDModel} /></div>:<></>}
      {page.meta['Kohde_Video_youtube_code'].length >0?<div><h3>Videot</h3>{getContent('media')}</div>:null}
      {media.length>0
        ?<Media 
          page={page} 
          media={media} 
          showPlayButton={media.length >1}
          showThumbnails ={media.length >1}
          showBullets={media.length >1}
        />
        :null
      }
      {video.length>0
      ?video.map((item,index)=>(
        <div>
          <h3>{item.title}</h3>
          <iframe 
            title='oembed' 
            height={360} 
            width={window.screen.width>640?640:(window.screen.width*0.9).toFixed(0)} 
            src={item.url} 
            framebBorder={0} 
            webkitAllowFullScreen={'true'} 
            mozAllowFullScreen={'true'} 
            allowFullScreen={true} >
          </iframe> 
          <div dangerouslySetInnerHTML={{ __html: item.caption}} />
        </div>
      ))
      :null
    }
    <hr/>
    

    {oembed.length>0
      ?oembed.map((item,index)=>(
        <div>
          <h3>{item.title}</h3>
          <iframe title='oembed' width={item.width} height={item.height} src={item.url} framebBorder={0} webkitAllowFullScreen={'true'} mozAllowFullScreen={'true'} allowFullScreen={true} ></iframe> 
          <div dangerouslySetInnerHTML={{ __html: item.caption}} />
        </div>
      ))
      :null
    }
    {page.meta['hylytnet_facebook_url'].length>0 
      ?<div>
        <h3>Hylystä Facebookissa:</h3>
        {page.meta['hylytnet_facebook_url'].map((fbpost,index) =>(
          <div><a href={fbpost} target="_blank" rel="noreferrer">{fbpost}</a></div>))}
      </div>
      :null}
    </Container>:null}
    <Container>
    <Comments  page={page}/>
    </Container>
    </div>
  )

}
export default Hylkykortti