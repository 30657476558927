import '../../MapSidebar.css'
import { useEffect } from 'react'
import Form from 'react-bootstrap/Form'


const MapSidebar=({map,overlays,wreckId,showAllWrecks,setShowAllWrecks,showToggleControl})=>{

  //const [chosenOwerlays, setChosenOwerlays] = useState([])
  //const [dummy,setDummy]=useState(false)


  useEffect(()=>{
    console.log('mapit ja overlayt')
    //useOwelayoptions init valmistelee kartan localstorage-arvojen perusteella
    //ja vie ne komponentin tilaan(miksi?).
  },[map,overlays])

  const openNav =()=>{
    console.log('sidebar click')
    if( (document.getElementById("mySidepanel")).offsetWidth >0){
      closeNav();
   }else{
     document.getElementById("mySidepanel").style.width = "250px";
     document.getElementById("mySidepanel").style.padding = "25px";
     document.getElementsByClassName('closebtn')[0].style.visibility="visible";
   } 

  }
  const closeNav =()=>{
    document.getElementById("mySidepanel").style.width = "0";
    document.getElementsByClassName('closebtn')[0].style.visibility="hidden";
    document.getElementById("mySidepanel").style.padding = "0px";
  }


  const handleChange=(event)=>{
    event.type.selected=event.value
    window.localStorage.setItem(event.type.name,event.value?'true':'false')
    map.overlayMapTypes.clear()
    if(event.value){
      //remove other types in layer if new type is chosen
      overlays.forEach(overlay => {
        if(overlay.index===event.type.index && event.type.name !==overlay.name){
          overlay.selected=false
          window.localStorage.setItem(overlay.name, 'false')
        }
      })
    }     
    
    const active =overlays.find(overlay=>overlay.index===0 && overlay.selected)
    if(active && active.name !=='Google Maps'){
      map.overlayMapTypes.push(active)
    }
    const toplayer = overlays.find(overlay=>overlay.index===1 && overlay.selected)
    if(toplayer){
      map.overlayMapTypes.push(toplayer)
    }
    overlays.forEach(owl=>console.log('stored:',owl.name, window.localStorage.getItem(owl.name)))
    console.log('..')
    
  }

  if(!map || overlays.length===0 ){
    return (<div>ladataan paneelia</div>)
  }

return(
  <div id="mysidebar" style={{zIndex: 0, position: 'absolute'}} >
    <button className="closebtn" onClick={closeNav}>×</button>
    <div id="mySidepanel" className="sidepanel">
    <h5>KARTTATASOT</h5>
      <div>
        <div>Karttapohja:</div>
      {overlays.filter(ol=>ol.index===0).map(overlay=>(
        <div  key={`divi${overlay.name}`} ><label>
          <input 
            type='radio'
            name='baselayer' 
            value={overlay.selected}
            key={overlay.name}
            defaultChecked={overlay.selected}
            //checked={()=>overlay.selected}
            onChange={(e)=>handleChange({type:overlay, value: e.target.checked })}
          /> 
          { } {overlay.title} 
          </label></div>)


      )}
      </div>
      <hr/>
      <div>
        <div>Lisätaso:</div>
      {overlays.filter(ol=>ol.index>0).map(overlay =>
        <label key={`label${overlay.name}`}>
          <input 
            type='checkbox' 
            value={overlay.selected}
            key={overlay.name}
            defaultChecked={overlay.selected} 
            onChange={(e)=>handleChange({type:overlay, value: e.target.checked })}
           
          /> 
          { } {overlay.title} 
          </label>
      )}
      { showToggleControl
      ?<div><hr/>
        <Form.Check 
          type="switch"
          id="custom-switch"
          label="Näytä kaikki hylyt kartalla"
          defaultChecked={showAllWrecks}
          onChange={(e)=>setShowAllWrecks(e.target.checked)}
        />
        <div>Kartalla näkyy: {showAllWrecks?'kaikki':'hakutulos'} </div>
      </div>
      :null}
      </div>

        <button className="openbtn" onClick={(e)=>openNav(e.target)}>☰</button>
      </div>
    </div> 



)

}
export default MapSidebar