import { useEffect, useState } from "react"
//import { useParams } from "react-router-dom"
//import { Container, Row } from "react-bootstrap"
import {Container,Row,Col} from 'react-bootstrap'
import hylkyservice from '../services/hylkykortti'
import ContactForm from "./ContactForm"
import ReportNewWreckForm from "./ReportNewWreckForm"
import Sanasto from "./Sanasto"


const Page = ({id, handleContentUpdate,clearAllFilters}) =>{

  //const[pageId,setPageId] = useState(0)
  const [page,setPage] = useState(null)
  //const id = useParams().id


 

  useEffect( ()=>{
    const fetchData = async ()=>{
      if(id>0){
        //setPageId(id)
        const newPage = await hylkyservice.getWPPage(id)
        //newPage =newPage.replace('[tabs]','<ul class="nav nav-tabs">')
        //newPage = newPage.replace('[tab title=”', )
        //pgrep = /\[tab title=\”C\”\]/ig
        if(id===2){
          clearAllFilters()
        }

        setPage(newPage)
      }else{
        setPage(null)
      }
    }
    fetchData()
    //handleContentUpdate()
  },[id])

  if(!page || page ===undefined){
    return(
      <div>loading...</div>
    )
  }

  //const avaa = (input) =>{


  //}
  //const foo="<Container>Baar</Container>"

  //return foo

  const renderSwitch =(pageId) => {
    switch(pageId) {
      case 9770:
        return <Row><Col><ReportNewWreckForm/></Col><Col><ContactForm /></Col></Row>;
      case 4028:
        return <Sanasto content={page.content.rendered}/>;
      default:
        return  <p dangerouslySetInnerHTML={{ __html: page.content.rendered }} />;
    }    

  }

  return (
    <Container>
      <h2>{page.title.rendered}</h2>
      {renderSwitch(id)}
    </Container>
  )


}
export default Page