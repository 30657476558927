import {Loader } from '@googlemaps/js-api-loader'
import MarkerCluster from '@googlemaps/markerclustererplus'
//import { map } from 'lodash';
import {renderLatLng} from '../../utils/utils'
import config from '../../config';


var myMap;
var myMarker;

var myMarkers=[];
var myClusterer;



const loader = new Loader({
  apiKey: config.GM_API_KEY,
  version: "weekly",
  //...additionalOptions,
});

const initMap=  (markers,setClusterer,setMap,setInfoWindow,setClickedMarker)=>{
  console.log('****jsmap initMap******')
  const foo = loader.load()
  .then(() => {
    const map = new window.google.maps.Map(document.getElementById("map"), {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 8,
      clickableIcons: false,
      //disableDefaultUI: true,
      mapTypeControl: false,
      minZoom: 4,
      streetViewControl: false,
      gestureHandling: 'auto'


    });
   
    window.myMap=map;
    const initBounds = map.getBounds();
    map.setOptions()
    setMap(map);
    const infowindow = new window.google.maps.InfoWindow({
      content: "",
      disableAutoPan: true,
    })
    
    setInfoWindow(infowindow);
    const mapmarkers= [];
    markers.forEach(element => {
      const pos = { lat: Number(element.meta.gpsLatitude[0]), lng: Number(element.meta.gpsLongitude[0])  }
      const posrendr=renderLatLng( pos);
      const newmarker = new window.google.maps.Marker({
        map: map,
        position: pos,
        title: element.title.rendered,
        icon: element.marker,
        id: element.id,
        content: `<div class="map-item-info" ><a href="/hylkykortti/${element.id}">${element.title['rendered']}</a>
        <div>${ posrendr}</div> </div><div>`
      })
    element.gMarker= newmarker;
    mapmarkers.push(newmarker);
    newmarker.addListener("click", () => {
      infowindow.setContent(newmarker.content.concat('<div>Ladataan<br/>Lisäkenttiä...</div>'))
      infowindow.open(map,newmarker);
      setClickedMarker(newmarker);
    });


  });
  myClusterer = new MarkerCluster(map,mapmarkers,{
    minimumClusterSize: 6,
    maxZoom: 13,
    ignoreHidden: true,
    "custom-clustericon" : undefined,
    imagePath: "/img/m",
    //style: clusterStyles,
    
  });
  //myClusterer.fitMapToMarkers();
  myMarker= mapmarkers;
  //myMap= map;
  setClusterer(myClusterer)

  return (window.myClusterer,window.map)
})
return foo;
}

////navi
////////// Navigation ////////
var nav_marker; 
var infowindow;
var  navigationWatchId;
var currentMovingPosition;
var follow_mode=false;
var navmap;


function hylytnet_Navigation(map){
      navmap=map;
      navigator.geolocation.getCurrentPosition(
        successInitNavigation,
        errorNavCallback,
        { enableHighAccuracy:true, timeout:10000, maximumAge:600000 }
      );
    }
    function errorNavCallback() { 
      //console.log("tuli error");
      
    }
    
    function successInitNavigation(position){
      var myLatlng = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
      currentMovingPosition=position;
      nav_marker  =   new window.google.maps.Marker({
        position: myLatlng,
        icon:  '/img/geolocation-pin-red.png',
        map: navmap,
      });
      var check_mark='';
      if(follow_mode){
        check_mark='checked';
      }  
      const posStr = renderLatLng( {lat: position.coords.latitude,lng: position.coords.longitude });
      const contentString =
      '<div>' +
      '<div >' +
      'Sijaintisi: <br/>'+ posStr + '<br/>'+
      myLatlng +'<br/>'+
      '<input type="checkbox" id="followCheck" onchange="followme(this)" '+ check_mark + ' >'+
      '<span>Seuraa sijaintia</span>'+
      "</div>" +
      "</div>";
      infowindow = new window.google.maps.InfoWindow({
        content: contentString,
      });
      nav_marker.addListener("click", () => {
        infowindow.open({
          anchor: nav_marker,
          navmap,
          shouldFocus: true,
        });
      });
      
      console.log('Navipos',position.coords.latitude,position.coords.longitude);
      navigationWatchId = navigator.geolocation.watchPosition(
        successNavigate,
        errorNavCallback,
        {  enableHighAccuracy:true, timeout:60000, maximumAge:0 }
      );
      console.log('piilo', document.getElementById('dir-searchinput-geo-lat'));  
      document.getElementById('dir-searchinput-geo-lat').setAttribute('value',position.coords.latitude,);
      document.getElementById('dir-searchinput-geo-lng').setAttribute('value',position.coords.longitude,);
    }
    
    function successNavigate(position) {
      var myLatlng = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
      const posStr = renderLatLng( {lat: position.coords.latitude,lng: position.coords.longitude });
      /*follow_mode=document.getElementById('followCheck').checked*/
      var check_mark='';
      if(follow_mode){
        check_mark='checked';
      }
      const newContentString =
      '<div>' +
      '<div >' +
      'Sijaintisi: <br/>'+ posStr + '<br/>'+
      myLatlng +'<br/>'+
      '<input type="checkbox" id="followCheck" onchange="followme(this)" '+ check_mark + ' >'+
      '<span>Seuraa sijaintia</span>'+
      "</div>" +
      "</div>";
      infowindow.setContent(newContentString);
      nav_marker.setPosition(myLatlng);
      if (follow_mode){
        navmap.panTo(nav_marker.getPosition());
      }

      console.log(position.coords.latitude +','+position.coords.longitude);
      document.getElementById('dir-searchinput-geo-lat').setAttribute('value',position.coords.latitude,);
      document.getElementById('dir-searchinput-geo-lng').setAttribute('value',position.coords.longitude,);
      currentMovingPosition=position;
    }
    
    function stopWatching(watchId){
      navigator.geolocation.clearWatch(watchId);
    }
    
    function followme(){
      follow_mode=document.getElementById('followCheck').checked
      //console.log('checked',element, d)
      if(window.localStorage) {
        localStorage.setItem("follow_mode", follow_mode);
      }
    }
    ////////// Navigation ends ////////
//end navi

window.initMap = initMap;
//window.filterMarkers = filterMarkers;
window.myMarkers =myMarkers;
window.myClusterer= myClusterer;
window.followme = followme;
window.hylytnet_Navigation=hylytnet_Navigation;

export default {
  initMap,
  myMarker,
  followme,
  hylytnet_Navigation,
  stopWatching,
}

