import { useState } from "react"

//import sendFeedBack from '../services/feedback'
import axios from "axios"
import config from "../config"
import { Alert } from "react-bootstrap"


const ContactForm =() =>{
  const [formStatus, setFormStatus] = useState('Lähetä')
  const [name,setName]= useState("")
  const [email,setEmail]=useState("")
  const [topic,setTopic]=useState("")
  const [msg,setMsg]=useState("")

  const styleForm =  { 
    background: '#fafafa',
    border: '1px solid #cfcfcf',
    
  }
  const styleLabel = {
      display: 'inline-block',
      maxWidth: '100%',
      marginBottom: '5px',
      fontWeight: 700
  
  }

  const handleNameChange=(e)=>{
    setName(e.target.value)  
  }
  const handleEmailChange=(e) =>{
    setEmail(e.target.value)
  }
  const handeTopicChange=(e)=>{
    setTopic(e.target.value)
  }
  const handleMsgChange=(e)=>{
    setMsg(e.target.value)
  }

  const handleFormSend = async (event) =>{
    event.preventDefault()
    setFormStatus("Lähetetään...")
    console.log('button clicked', event.target)
    const feedback = {
      name: name,
      email: email,
      topic: topic,
      msg: msg
    }
    axios.post(`${config.FORM_SERVER}/api/feedback`,feedback)
    .then(responce =>{
      console.log('server responce',responce.data)
      if (responce.data.status && responce.data.status === 'ok'){
        succesfullSend()
      }else{
        failedSend()
      }
      setFormStatus('Lähetä Uusi')
    })
    .catch(error =>{
      console.log('error oli', error)
      failedSend()
    })   
  }

  const succesfullSend=()=>{
    //setEmail('')
    //setName('')
    setMsg('')
    setTopic('')
    setFormStatus('Lähetä Uusi')
  }

  const failedSend=() =>{
    setFormStatus('Yritä uudelleeen')
  }

  const statusMsg=(status)=>{
    switch(status) {
      case 'Lähetä Uusi':
        return( 
          <Alert variant="success">
            <Alert.Heading>Palautteesi on vastaanotettu</Alert.Heading>
            <p>Lomake on vastaanotettu, kiitos antamistasi tiedoista</p>
          </Alert>
        );
        case 'Yritä uudelleen':
        return( 
          <Alert variant="warning">
            <Alert.Heading>Jotain meni pieleen</Alert.Heading>
            <p>Lomakkeen lähetys epäonnistui, yritä uudelleen</p>
          </Alert>
        );
        default:
        return <></>
    }
  }

  return (
    <form onSubmit={handleFormSend} style={styleForm}>
      <div>
        <h3>Palaute</h3>
        <div>
          Ehdota, kysy, kehu, parjaa, kerro ja vinkkaa
        </div>
       
        <label className="container mt-2">Nimi (Pakollinen) *<br/>
          <input type="text" className="form-control" id="senderName" name="senderName" value={name} onChange={handleNameChange} required/>
        </label><br/>
        <label className="container mt-2">Sähköposti (pakollinen) * <br/>
          <input type="email" className="form-control" id="email" name="email" value={email} onChange={handleEmailChange} required />
        </label><br/>

        <label className="container mt-2">Aihe<br/>
          <input type="text" className="form-control" id="aihe" name="aihe" value={topic} onChange={handeTopicChange} required />
        </label><br/>

        <label className="container mt-2" >Viesti (pakollinen) *<br/>
          <textarea id="viesti" className="form-control" name="viesti" rows={8} value={msg} onChange={handleMsgChange} required />
        </label><br/>


        <button className="btn btn-danger" type="submit">
          {formStatus}
        </button>
        {statusMsg(formStatus)}
      </div>
    </form>    

  )

}

export default ContactForm