import axios from 'axios'
import config from '../config'
const server =config.SERVER
const baseUrl= `${server}/wp-json/menus/v1/menus/mainmenu`

const getMenu=()=>{
  const request = axios.get(baseUrl) 
  return request.then(response => response.data)
}
export default getMenu

